import React from "react";
import "./modalSelect.css";

const ModalSelect = (props) => {
  const selectedStyles = {
    color: props.selected ? "white" : null,
    backgroundColor: props.selected ? "#0F56BF" : null,
    fontSize: props.mobile ? "3vw" : "1vw",
    width: props.width
  };
  return (
    <div
      className="modal-select"
      style={selectedStyles}
      onClick={props.onClick}
    >
      {props.displayValue}
    </div>
  );
};

export default ModalSelect;
