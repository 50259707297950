const translateAmenity = (amenity) => {
  switch (amenity) {
    case "Balcony": {
      return "Balcón";
    }
    case "Security": {
      return "Seguridad";
    }
    case "Garden": {
        return "Jardín";
    }
    case "Pet Friendly": {
        return "Mascotas";
    }
    case "Air Conditioner": {
        return "Clima (AC)";
    }
    case "Washing Machine": {
        return "Lavadora";
    }
    case "Dryer": {
        return "Secadora";
    }
    case "Refrigerator": {
        return "Refrigerador";
    }
    case "Stove": {
        return "Estufa";
    }
    case "Oven": {
        return "Horno";
    }
    case "Microwave": {
        return "Microondas";
    }
    case "Dishwasher": {
        return "Lavavajillas";
    }
    case "Curtains": {
        return "Cortinas";
    }
    case "Wardrobes": {
        return "Armarios";
    }
    case "Parking": {
        return "Estacionamiento";
    }
    default: {
      return "Error";
    }
  }
};

export default translateAmenity;
