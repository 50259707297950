import React from "react";
import Select from "react-select";
import "./dropdownBubble.css";

import PaddingDiv from "../PaddingDiv";

const DropdownBubble = (props) => {
  const styles = {
    height: props.height,
    width: props.width,
    filter: props.shadow ? "drop-shadow(0px 5px 4px rgb(160, 160, 160))" : null,
    border: props.border ? "1px solid gray" : null,
  };
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      height: "90%",
      borderRadius: "100px",
      flexGrow: 100,
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      textAlign: "center",
      // zIndex: 9999,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "100px",
      border: state.isFocused ? 0 : 0,
      boxShadow: state.isFocused ? 0 : 0,
      background: "#fff",
      fontSize: props.fontSize,
      fontFamily: props.controlFontFamily,
      minHeight: "100%",
      height: "100%",
      "&:hover": {
        border: state.isFocused ? 0 : 0,
      },
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "100%",
      padding: "0 6px",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "100%",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      ":hover": {
        color: "black",
      },
      color: "black",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: props.fontSize,
      fontFamily: props.optionFontFamily,
    }),
  };
  return (
    <div id="dropdown-bubble" style={styles}>
      <PaddingDiv
        height="90%"
        width={props.padded ? "80%" : "96%"}
        flexDirection="row"
        alignItems="center"
      >
        {props.icon}
        <Select
          isSearchable={false}
          styles={customStyles}
          options={props.options}
          menuPortalTarget={document.body}
          defaultValue={props.defaultValue}
          value={props.value}
          onChange={(n) => props.onChange(n)}
        />
      </PaddingDiv>
    </div>
  );
};

export default DropdownBubble;
