import React from "react";
import "./propertyHero.css";

const PropertyHero = (props) => {
  return (
    <div className="property-hero">
      <div id="property-hero-title">{props.title}</div>
      {props.icon}
      <div id="property-hero-desc">{props.desc}</div>
    </div>
  );
};

export default PropertyHero;
