import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./mobilePropertyPopup.css";

import RoundedButton from "../RoundedButton";

const MobilePropertyPopup = (props) => {
  return (
    <Popup
      modal
      open={props.open}
      closeOnEscape={false}
      closeOnDocumentClick={false}
      contentStyle={{ width: "65vw", borderRadius: "0.7vw" }}
    >
      <div className="mobile-popup-container">
        <div onClick={props.onClosePopup} className="mobile-popup-cross">
          X
        </div>
        <h1 style={{ fontSize: "1.2em" }}>{props.title}</h1>
        <img src={props.img} className="mobile-popup-icon" />
        <p className="mobile-popup-text">{props.text}</p>
        <div className="mobile-popup-buttons">
          <RoundedButton
            displayValue="Continuar"
            buttonColor="#0F56BF"
            textColor="white"
            fontFamily="SuisseBPIntl-Antique"
            fontSize="0.7em"
            width="45%"
            height="35px"
            className="mobile-continue-button"
            onClick={props.onContinue}
          />
          <RoundedButton
            displayValue="Cancelar"
            buttonColor="#f7382a"
            textColor="white"
            fontFamily="SuisseBPIntl-Antique"
            fontSize="0.7em"
            width="45%"
            height="35px"
            className="mobile-continue-button"
            onClick={props.onClosePopup}
          />
        </div>
      </div>
    </Popup>
  );
};

export default MobilePropertyPopup;
