import React, { useState, useEffect } from "react";
import "./mobileFaqPage.css";
import "./faqPage.css";

import { ReactComponent as DownArrowIcon } from "../../assets/icons/down.svg";

import Header from "../../Layouts/Header";
import Footer from "../../Layouts/Footer";
import MobileHeader from "../../Layouts/MobileHeader";
import MobileFooter from "../../Layouts/MobileFooter";

const FaqPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    return () => {
      window.removeEventListener("resize", handleWindowChange);
    };
  });
  const isMobile = windowWidth < 500;
  const [answerOne, setAnswerOne] = useState(false);
  const [answerTwo, setAnswerTwo] = useState(false);
  const [answerThree, setAnswerThree] = useState(false);
  const [answerFour, setAnswerFour] = useState(false);
  const [answerFive, setAnswerFive] = useState(false);
  const showAnswerOne = () => {
    setAnswerOne(!answerOne);
  };
  const showAnswerTwo = () => {
    setAnswerTwo(!answerTwo);
  };
  const showAnswerThree = () => {
    setAnswerThree(!answerThree);
  };
  const showAnswerFour = () => {
    setAnswerFour(!answerFour);
  };
  const showAnswerFive = () => {
    setAnswerFive(!answerFive);
  };
  if (isMobile) {
    return (
      <>
        <div id="mobile-faq-page">
          <MobileHeader />
          <div id="mobile-page-title">Preguntas Frecuentes</div>
          <div className="mobile-page-header">
            Resolvemos todas tus dudas en esta sección:
          </div>
          <div className="mobile-page-divider" />
          <div className="mobile-question" onClick={() => showAnswerOne()}>
            <div className="mobile-faq-question-internal">
              ¿Puedo visitar la propiedad antes de aplicar?
            </div>
            <DownArrowIcon
              className={
                answerOne
                  ? "mobile-faq-page-down-icon-rotated"
                  : "mobile-faq-page-down-icon"
              }
            />
          </div>
          {answerOne && (
            <div className="mobile-answer">
              Si, puedes visitar todas las propiedades que creas convenientes
              hasta encontrar una que se adapte al 100% a tus necesidades.
              Contarás con el apoyo de un asesor Navío para darte toda la
              información y atenderte personalmente en las visitas al Inmueble.
            </div>
          )}
          <div className="mobile-page-divider" />
          <div className="mobile-question" onClick={() => showAnswerTwo()}>
            <div className="mobile-faq-question-internal">
              ¿Qué necesito para poder aplicar?
            </div>
            <DownArrowIcon
              className={
                answerTwo
                  ? "mobile-faq-page-down-icon-rotated"
                  : "mobile-faq-page-down-icon"
              }
            />
          </div>
          {answerTwo && (
            <div className="mobile-answer">
              El proceso de aplicación es muy sencillo. Solo necesitas elegir la
              propiedad que te guste y entregarnos los siguientes documentos de
              manera digital: INE o Pasaporte vigente, Estados de cuenta de los
              últimos 3 meses y recibos de nómina de los últimos 3 meses.
            </div>
          )}
          <div className="mobile-page-divider" />
          <div className="mobile-question" onClick={() => showAnswerThree()}>
            <div className="mobile-faq-question-internal">
              ¿En cuánto tiempo puedo entrar a la propiedad a la que apliqué?
            </div>
            <DownArrowIcon
              className={
                answerThree
                  ? "mobile-faq-page-down-icon-rotated"
                  : "mobile-faq-page-down-icon"
              }
            />
          </div>
          {answerThree && (
            <div className="mobile-answer">
              En menos de 24 horas ya puedes estrenar una propiedad con Navío,
              después de haber realizado los pasos del punto anterior.
            </div>
          )}
          <div className="mobile-page-divider" />
          <div className="mobile-question" onClick={() => showAnswerFour()}>
            <div className="mobile-faq-question-internal">
              ¿Necesito fiador/aval para rentar una propiedad?{" "}
            </div>
            <DownArrowIcon
              className={
                answerFour
                  ? "mobile-faq-page-down-icon-rotated"
                  : "mobile-faq-page-down-icon"
              }
            />
          </div>
          {answerFour && (
            <div className="mobile-answer">
              No. Rentando con Navío no es necesario que tengas fiador ni aval.
            </div>
          )}
          <div className="mobile-page-divider" />
          <div className="mobile-question" onClick={() => showAnswerFive()}>
            <div className="mobile-faq-question-internal">
              ¿Cuál es el costo de visitar o aplicar para una propiedad?
            </div>
            <DownArrowIcon
              className={
                answerFive
                  ? "mobile-faq-page-down-icon-rotated"
                  : "mobile-faq-page-down-icon"
              }
            />
          </div>
          {answerFive && (
            <div className="mobile-answer">
              {" "}
              $0 pesos. Escoge la propiedad que más te guste y un Agente Navío
              te ayudará en todo el proceso.
            </div>
          )}
          <div id="mobile-faq-page-last-text">
            Si deseas que te asesoremos gratis con <br /> cualquier otra
            pregunta, da click{" "}
            <span
              onClick={() =>
                window.open(
                  encodeURI(
                    `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
                  )
                )
              }
            >
              aquí
            </span>
          </div>
        </div>
        <MobileFooter />
      </>
    );
  } else {
    return (
      <>
        <div id="faq-page">
          <Header />
          <div id="page-title">Preguntas Frecuentes</div>
          <div className="page-header">
            Resolvemos todas tus dudas en esta sección:
          </div>
          <div className="page-divider" />
          <div className="question" onClick={() => showAnswerOne()}>
            <div>¿Puedo visitar la propiedad antes de aplicar?</div>
            <DownArrowIcon
              className={
                answerOne ? "faq-page-down-icon-rotated" : "faq-page-down-icon"
              }
            />
          </div>
          {answerOne && (
            <div className="answer">
              Si, puedes visitar todas las propiedades que creas convenientes
              hasta encontrar una que se adapte al 100% a tus necesidades.
              Contarás con el apoyo de un asesor Navío para darte toda la
              información y atenderte personalmente en las visitas al Inmueble.
            </div>
          )}
          <div className="page-divider" />
          <div className="question" onClick={() => showAnswerTwo()}>
            ¿Qué necesito para poder aplicar?
            <DownArrowIcon
              className={
                answerTwo ? "faq-page-down-icon-rotated" : "faq-page-down-icon"
              }
            />
          </div>
          {answerTwo && (
            <div className="answer">
              El proceso de aplicación es muy sencillo. Solo necesitas elegir la
              propiedad que te guste y entregarnos los siguientes documentos de
              manera digital: INE o Pasaporte vigente, Estados de cuenta de los
              últimos 3 meses y recibos de nómina de los últimos 3 meses.
            </div>
          )}
          <div className="page-divider" />
          <div className="question" onClick={() => showAnswerThree()}>
            ¿En cuánto tiempo puedo entrar a la propiedad a la que apliqué?
            <DownArrowIcon
              className={
                answerThree
                  ? "faq-page-down-icon-rotated"
                  : "faq-page-down-icon"
              }
            />
          </div>
          {answerThree && (
            <div className="answer">
              En menos de 24 horas ya puedes estrenar una propiedad con Navío,
              después de haber realizado los pasos del punto anterior.
            </div>
          )}
          <div className="page-divider" />
          <div className="question" onClick={() => showAnswerFour()}>
            ¿Necesito fiador/aval para rentar una propiedad?
            <DownArrowIcon
              className={
                answerFour ? "faq-page-down-icon-rotated" : "faq-page-down-icon"
              }
            />
          </div>
          {answerFour && (
            <div className="answer">
              No. Rentando con Navío no es necesario que tengas fiador ni aval.
            </div>
          )}
          <div className="page-divider" />
          <div className="question" onClick={() => showAnswerFive()}>
            ¿Cuál es el costo de visitar o aplicar para una propiedad?
            <DownArrowIcon
              className={
                answerFive ? "faq-page-down-icon-rotated" : "faq-page-down-icon"
              }
            />
          </div>
          {answerFive && (
            <div className="answer">
              $0 pesos. Escoge la propiedad que más te guste y un Agente Navío
              te ayudará en todo el proceso.
            </div>
          )}
          <div id="faq-page-last-text">
            Si deseas que te asesoremos gratis con <br /> cualquier otra
            pregunta, da click{" "}
            <span
              onClick={() =>
                window.open(
                  encodeURI(
                    `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
                  )
                )
              }
            >
              aquí
            </span>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default FaqPage;
