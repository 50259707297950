import React, { useState, useEffect } from "react";
import "./mobilePrivacyPage.css";
import "./privacyPage.css";

import Footer from "../../Layouts/Footer";
import Header from "../../Layouts/Header";
import MobileHeader from "../../Layouts/MobileHeader";
import MobileFooter from "../../Layouts/MobileFooter";

const PrivacyPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    return () => {
      window.removeEventListener("resize", handleWindowChange);
    };
  });
  const isMobile = windowWidth < 500;
  if (isMobile) {
    return (
      <>
        <div id="mobile-privacy-page">
          <MobileHeader />
          <div id="mobile-page-title">Aviso de Privacidad</div>
          <div className="mobile-page-section">DATOS DEL RESPONSABLE</div>
          <div className="mobile-page-content">
            JURÍDICO PATRIMONIAL ASI, S.A. DE C.V. (en lo sucesivo, “NAVÍO”)
            hace de su conocimiento sus derechos y obligaciones previstos en la
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares, y que será responsabilidad de esta sociedad el uso y
            tratamiento de los mismos. NAVÍO tiene su domicilio social y fiscal
            ubicado en Av. Lázaro Cárdenas 1007, Residencial Santa Bárbara, San
            Pedro Garza García, Nuevo León y nos puede contactar vía correo
            electrónico en: privacidad@navio.mx
          </div>
          <div className="mobile-page-section">RESPONSABILIDAD</div>
          <div className="mobile-page-content">
            NAVÍO se obliga y compromete a proteger aquellos Datos Personales
            que sean proporcionados y/o recabados de cualquier usuario (en lo
            sucesivo, “TITULAR”) de la plataforma https://navio.mx ; ya sea, a
            través de cualquier medio electrónico, formato en papel, formato
            electrónico de la página de internet de NAVÍO, teléfono u otro que
            tenga como finalidad la obtención de Datos Personales; así mismo, se
            compromete a solamente utilizar dichos datos por el período que sea
            requerido; o bien, tan pronto las legislaciones que regulen la
            relación entre NAVÍO y el TITULAR de los datos permita su
            eliminación, de conformidad y en cumplimiento a las obligaciones
            contenidas en las Leyes correspondientes.
          </div>
          <div className="mobile-page-section">DATOS PERSONALES QUE SE RECABAN</div>
          <div className="mobile-page-content">
            Se consideran “Datos Personales” cualquier información que acredite
            la personalidad e identidad de su persona tal y como son: Nombre(s)
            y apellido(s), fecha de nacimiento, sexo, descripción física,
            nacionalidad, estado civil, domicilio(s), número telefónico (casa o
            celular), correo electrónico, Registro Federal de Contribuyente
            (RFC), Número de Seguro Social, Clave Única de Registro de Población
            (CURP), así como los que permitan identificar de manera plena la
            personalidad del TITULAR.
          </div>
          <div className="mobile-page-section">
            DATOS PERSONALES SENSIBLES, PATRIMONIALES Y FINANCIEROS QUE SE
            RECABAN
          </div>
          <div className="mobile-page-content">
            NAVÍO hace del conocimiento del TITULAR que también podrán ser
            recabados Datos Personales financieros y/o patrimoniales
            relacionados con su situación económica, resultado de estudios
            económicos, entre otros documentos cuyo objeto sea identificar el
            estatus económico del TITULAR. Aunado a todo lo anterior, NAVÍO
            informa al TITULAR que de ser necesario, solicitará referencias
            laborales a las empresas en las que el TITULAR haya trabajado y/o
            llevará a cabo investigaciones relacionadas con sus antecedentes
            judiciales, crediticias y de conducta social.
          </div>
          <div className="mobile-page-section">FINALIDADES</div>
          <div className="mobile-page-content">
            Siendo estos Datos Personales y Datos Personales Sensibles recabados
            única y exclusivamente para contactarlo y por ser necesarios para
            los procesos de selección, evaluación, valoración, de los TITULARES
            y a fin de determinar el apego del perfil de la relación contractual
            del TITULAR, en su caso la probable relación de servicios entre el
            TITULAR y NAVÍO¸ adicional a la elaboración del expediente del
            TITULAR dentro de NAVÍO.
          </div>
          <div className="mobile-page-content">
            NAVÍO se compromete a que todos los Datos Personales y/o Datos
            Personales Sensibles que el TITULAR le proporcione, o que NAVÍO
            obtenga de él, serán tratados bajo las más estrictas medidas de
            seguridad que garanticen su confidencialidad y buen uso para las
            finalidades descritas en el presente Aviso de Privacidad.
          </div>
          <div className="mobile-page-section">TRATAMIENTO DE LA INFORMACIÓN.</div>
          <div className="mobile-page-content">
            NAVÍO garantiza que los Datos Personales y/o Datos Personales
            Sensibles que recabe u obtenga del TITULAR serán utilizados única y
            exclusivamente para los fines mencionados en el presente aviso de
            privacidad, que dicha información no estará al alcance ni se podrá
            acceder a ella por personas ajenas a los procesos mencionados, que
            estarán protegidos con las medidas de seguridad físicas,
            administrativas y técnicas suficientes para su protección y
            confidencialidad y que dichos datos serán tratados solamente por el
            período de tiempo que sea requerido para el cumplimiento de los
            fines mencionados en el presente aviso de privacidad y una vez
            cumplidos estos fines se procederá a la cancelación, bloqueo y
            eliminación de los mismos en los tiempos y formas que las Leyes
            respectivas lo indiquen.
          </div>
          <div className="mobile-page-content">
            De igual manera, y por ser necesario para el cumplimiento de los
            fines mencionados por este aviso de privacidad, NAVÍO podrá
            transferir sus Datos Personales y/o Datos Personales Sensibles a
            clínicas, autoridades federales, o locales y/o prestadores de
            servicios de NAVÍO cuando los procesos de selección, evaluación,
            valoración y la probable contratación del TITULAR requieran del
            análisis, opinión o antecedente de dichos organismos y en cuyo caso,
            la información que sea transferida será tratada con apego a lo
            estipulado por este aviso de privacidad. NAVÍO se compromete a no
            transferir sus Datos Personales y/o Datos personales sensibles a
            ningún tercero sin el consentimiento del TITULAR, salvo las
            excepciones previstas en el artículo 37 de la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares, así
            como a realizar esta transferencia con apego a los términos que fija
            dicha Ley que a la letra dice:
          </div>
          <div className="mobile-page-content">
            “Artículo 37.- Las transferencias nacionales o internacionales de
            datos podrán llevarse a cabo sin el consentimiento del titular
            cuando se dé alguno de los siguientes supuestos: I. Cuando la
            transferencia esté prevista en una Ley o Tratado en los que México
            sea parte; II. Cuando la transferencia sea necesaria para la
            prevención o el diagnóstico médico, la prestación de asistencia
            sanitaria, tratamiento médico o la gestión de servicios sanitarios;
            III. Cuando la transferencia sea efectuada a sociedades
            controladoras, subsidiarias o afiliadas bajo el control común del
            responsable, o a una sociedad matriz o a cualquier sociedad del
            mismo grupo del responsable que opere bajo los mismos procesos y
            políticas internas; IV. Cuando la transferencia sea necesaria por
            virtud de un contrato celebrado o por celebrar en interés del
            titular, por el responsable y un tercero; V. Cuando la transferencia
            sea necesaria o legalmente exigida para la salvaguarda de un interés
            público, o para la procuración o administración de justicia; VI.
            Cuando la transferencia sea precisa para el reconocimiento,
            ejercicio o defensa de un derecho en un proceso judicial, y VII.
            Cuando la transferencia sea precisa para el mantenimiento o
            cumplimiento de una relación jurídica entre el responsable y el
            titular.”
          </div>
          <div className="mobile-page-content">
            Así como a realizar esta transferencia con apego a los términos que
            fija dicha Ley.
          </div>
          <div className="mobile-page-section">
            DERECHO A LIMITAR EL USO Y DIVULGACIÓN DE SUS DATOS PERSONALES
          </div>
          <div className="mobile-page-content">
            Si el TITULAR desea limitar el uso o divulgación de sus Datos
            Personales y/o Datos Personales Sensibles podrá ejercer de manera
            gratuita y en cualquier momento dichos derechos estipulados en la
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares vigente, para lo cual podrá solicitar un formato para
            la delimitación de uso y divulgación, por el siguiente medio: Para
            el ejercicio de dichos derechos el TITULAR podrá llenar el formato
            correspondiente que será proporcionado por NAVÍO en la siguiente
            dirección:
          </div>
          <div className="mobile-page-content">
            <ul>
              <li>
                Avenida Lázaro Cárdenas 1007, Residencial Santa Bárbara, San
                Pedro Garza García, Nuevo León 66266
              </li>
            </ul>
          </div>
          <div className="mobile-page-content">
            El formato deberá ir acompañado de una copia simple de un documento
            oficial que acredite la identidad del TITULAR e incluir una
            descripción clara y precisa de los Datos Personales y/o Datos
            Personales Sensibles respecto de los cuales ejercitará cualquiera de
            los derechos que le confiere la Ley, así mismo deberá proporcionar
            un correo electrónico, número telefónico y dirección para oír y
            recibir notificaciones a fin de comunicarle la respuesta a su
            petición y cualquier otra información que facilite la búsqueda y
            localización de sus datos para los fines requeridos. Así mismo con
            objeto de que el TITULAR pueda limitar el uso y divulgación de su
            información personal, le ofrecemos el siguiente medio:
          </div>
          <div className="mobile-page-content">
            <ul>
              <li>
                Su registro en el Listado de Exclusión de NAVÍO, a fin de que
                sus datos personales no sean tratados para fines
                mercadotécnicos, publicitarios o de prospección comercial por
                nuestra parte. Para mayor información se podrá dirigirse a los
                datos de contacto establecidos en el presente documento.
              </li>
            </ul>
          </div>
          <div className="mobile-page-section">
            Eliminación y cancelación de uso de Cookies
          </div>
          <div className="mobile-page-content">
            A continuación se muestra la forma de como eliminar y cancelar el
            registro de cookies para los navegadores de Internet Explorer,
            Mozilla Firefox, Google Chrome y Safari.
          </div>
          <div className="mobile-page-content-header">Internet Explorer</div>
          <div className="mobile-page-content">
            <span className="mobile-page-content-section">
              Eliminar los cookies actualmente almacenados
            </span>
            <ol>
              <li>Inicie el programa Internet Explorer.</li>
              <li>
                Seleccionar en la barra de menú la opción de Herramientas y
                posteriormente la opción de Opciones de Internet.
              </li>
              <li>
                En el apartado de Historial de Navegación se deberá presionar el
                botón de Eliminar.
              </li>
              <li>
                Se abrirá una ventana con varias opciones para eliminar, aquí
                solo se deberá seleccionar (recuadro seleccionado) la opción de
                Cookies.
              </li>
              <li>
                Se presionará el botón de Eliminar, se cerrará automáticamente
                la ventana.
              </li>
              <li>
                Finalmente se debe presionar el botón de OK para finalizar con
                la operación. Toda la información de cookies almacenada se habrá
                eliminado.
              </li>
            </ol>
          </div>
        </div>
        <MobileFooter />
      </>
    );
  } else {
    return (
      <>
        <div id="privacy-page">
          <Header />
          <div id="page-title">Aviso de Privacidad</div>
          <div className="page-section">DATOS DEL RESPONSABLE</div>
          <div className="page-content">
            JURÍDICO PATRIMONIAL ASI, S.A. DE C.V. (en lo sucesivo, “NAVÍO”)
            hace de su conocimiento sus derechos y obligaciones previstos en la
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares, y que será responsabilidad de esta sociedad el uso y
            tratamiento de los mismos. NAVÍO tiene su domicilio social y fiscal
            ubicado en Av. Lázaro Cárdenas 1007, Residencial Santa Bárbara, San
            Pedro Garza García, Nuevo León y nos puede contactar vía correo
            electrónico en: privacidad@navio.mx
          </div>
          <div className="page-section">RESPONSABILIDAD</div>
          <div className="page-content">
            NAVÍO se obliga y compromete a proteger aquellos Datos Personales
            que sean proporcionados y/o recabados de cualquier usuario (en lo
            sucesivo, “TITULAR”) de la plataforma https://navio.mx ; ya sea, a
            través de cualquier medio electrónico, formato en papel, formato
            electrónico de la página de internet de NAVÍO, teléfono u otro que
            tenga como finalidad la obtención de Datos Personales; así mismo, se
            compromete a solamente utilizar dichos datos por el período que sea
            requerido; o bien, tan pronto las legislaciones que regulen la
            relación entre NAVÍO y el TITULAR de los datos permita su
            eliminación, de conformidad y en cumplimiento a las obligaciones
            contenidas en las Leyes correspondientes.
          </div>
          <div className="page-section">DATOS PERSONALES QUE SE RECABAN</div>
          <div className="page-content">
            Se consideran “Datos Personales” cualquier información que acredite
            la personalidad e identidad de su persona tal y como son: Nombre(s)
            y apellido(s), fecha de nacimiento, sexo, descripción física,
            nacionalidad, estado civil, domicilio(s), número telefónico (casa o
            celular), correo electrónico, Registro Federal de Contribuyente
            (RFC), Número de Seguro Social, Clave Única de Registro de Población
            (CURP), así como los que permitan identificar de manera plena la
            personalidad del TITULAR.
          </div>
          <div className="page-section">
            DATOS PERSONALES SENSIBLES, PATRIMONIALES Y FINANCIEROS QUE SE
            RECABAN
          </div>
          <div className="page-content">
            NAVÍO hace del conocimiento del TITULAR que también podrán ser
            recabados Datos Personales financieros y/o patrimoniales
            relacionados con su situación económica, resultado de estudios
            económicos, entre otros documentos cuyo objeto sea identificar el
            estatus económico del TITULAR. Aunado a todo lo anterior, NAVÍO
            informa al TITULAR que de ser necesario, solicitará referencias
            laborales a las empresas en las que el TITULAR haya trabajado y/o
            llevará a cabo investigaciones relacionadas con sus antecedentes
            judiciales, crediticias y de conducta social.
          </div>
          <div className="page-section">FINALIDADES</div>
          <div className="page-content">
            Siendo estos Datos Personales y Datos Personales Sensibles recabados
            única y exclusivamente para contactarlo y por ser necesarios para
            los procesos de selección, evaluación, valoración, de los TITULARES
            y a fin de determinar el apego del perfil de la relación contractual
            del TITULAR, en su caso la probable relación de servicios entre el
            TITULAR y NAVÍO¸ adicional a la elaboración del expediente del
            TITULAR dentro de NAVÍO.
          </div>
          <div className="page-content">
            NAVÍO se compromete a que todos los Datos Personales y/o Datos
            Personales Sensibles que el TITULAR le proporcione, o que NAVÍO
            obtenga de él, serán tratados bajo las más estrictas medidas de
            seguridad que garanticen su confidencialidad y buen uso para las
            finalidades descritas en el presente Aviso de Privacidad.
          </div>
          <div className="page-section">TRATAMIENTO DE LA INFORMACIÓN.</div>
          <div className="page-content">
            NAVÍO garantiza que los Datos Personales y/o Datos Personales
            Sensibles que recabe u obtenga del TITULAR serán utilizados única y
            exclusivamente para los fines mencionados en el presente aviso de
            privacidad, que dicha información no estará al alcance ni se podrá
            acceder a ella por personas ajenas a los procesos mencionados, que
            estarán protegidos con las medidas de seguridad físicas,
            administrativas y técnicas suficientes para su protección y
            confidencialidad y que dichos datos serán tratados solamente por el
            período de tiempo que sea requerido para el cumplimiento de los
            fines mencionados en el presente aviso de privacidad y una vez
            cumplidos estos fines se procederá a la cancelación, bloqueo y
            eliminación de los mismos en los tiempos y formas que las Leyes
            respectivas lo indiquen.
          </div>
          <div className="page-content">
            De igual manera, y por ser necesario para el cumplimiento de los
            fines mencionados por este aviso de privacidad, NAVÍO podrá
            transferir sus Datos Personales y/o Datos Personales Sensibles a
            clínicas, autoridades federales, o locales y/o prestadores de
            servicios de NAVÍO cuando los procesos de selección, evaluación,
            valoración y la probable contratación del TITULAR requieran del
            análisis, opinión o antecedente de dichos organismos y en cuyo caso,
            la información que sea transferida será tratada con apego a lo
            estipulado por este aviso de privacidad. NAVÍO se compromete a no
            transferir sus Datos Personales y/o Datos personales sensibles a
            ningún tercero sin el consentimiento del TITULAR, salvo las
            excepciones previstas en el artículo 37 de la Ley Federal de
            Protección de Datos Personales en Posesión de los Particulares, así
            como a realizar esta transferencia con apego a los términos que fija
            dicha Ley que a la letra dice:
          </div>
          <div className="page-content">
            “Artículo 37.- Las transferencias nacionales o internacionales de
            datos podrán llevarse a cabo sin el consentimiento del titular
            cuando se dé alguno de los siguientes supuestos: I. Cuando la
            transferencia esté prevista en una Ley o Tratado en los que México
            sea parte; II. Cuando la transferencia sea necesaria para la
            prevención o el diagnóstico médico, la prestación de asistencia
            sanitaria, tratamiento médico o la gestión de servicios sanitarios;
            III. Cuando la transferencia sea efectuada a sociedades
            controladoras, subsidiarias o afiliadas bajo el control común del
            responsable, o a una sociedad matriz o a cualquier sociedad del
            mismo grupo del responsable que opere bajo los mismos procesos y
            políticas internas; IV. Cuando la transferencia sea necesaria por
            virtud de un contrato celebrado o por celebrar en interés del
            titular, por el responsable y un tercero; V. Cuando la transferencia
            sea necesaria o legalmente exigida para la salvaguarda de un interés
            público, o para la procuración o administración de justicia; VI.
            Cuando la transferencia sea precisa para el reconocimiento,
            ejercicio o defensa de un derecho en un proceso judicial, y VII.
            Cuando la transferencia sea precisa para el mantenimiento o
            cumplimiento de una relación jurídica entre el responsable y el
            titular.”
          </div>
          <div className="page-content">
            Así como a realizar esta transferencia con apego a los términos que
            fija dicha Ley.
          </div>
          <div className="page-section">
            DERECHO A LIMITAR EL USO Y DIVULGACIÓN DE SUS DATOS PERSONALES
          </div>
          <div className="page-content">
            Si el TITULAR desea limitar el uso o divulgación de sus Datos
            Personales y/o Datos Personales Sensibles podrá ejercer de manera
            gratuita y en cualquier momento dichos derechos estipulados en la
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares vigente, para lo cual podrá solicitar un formato para
            la delimitación de uso y divulgación, por el siguiente medio: Para
            el ejercicio de dichos derechos el TITULAR podrá llenar el formato
            correspondiente que será proporcionado por NAVÍO en la siguiente
            dirección:
          </div>
          <div className="page-content">
            <ul>
              <li>
                Avenida Lázaro Cárdenas 1007, Residencial Santa Bárbara, San
                Pedro Garza García, Nuevo León 66266
              </li>
            </ul>
          </div>
          <div className="page-content">
            El formato deberá ir acompañado de una copia simple de un documento
            oficial que acredite la identidad del TITULAR e incluir una
            descripción clara y precisa de los Datos Personales y/o Datos
            Personales Sensibles respecto de los cuales ejercitará cualquiera de
            los derechos que le confiere la Ley, así mismo deberá proporcionar
            un correo electrónico, número telefónico y dirección para oír y
            recibir notificaciones a fin de comunicarle la respuesta a su
            petición y cualquier otra información que facilite la búsqueda y
            localización de sus datos para los fines requeridos. Así mismo con
            objeto de que el TITULAR pueda limitar el uso y divulgación de su
            información personal, le ofrecemos el siguiente medio:
          </div>
          <div className="page-content">
            <ul>
              <li>
                Su registro en el Listado de Exclusión de NAVÍO, a fin de que
                sus datos personales no sean tratados para fines
                mercadotécnicos, publicitarios o de prospección comercial por
                nuestra parte. Para mayor información se podrá dirigirse a los
                datos de contacto establecidos en el presente documento.
              </li>
            </ul>
          </div>
          <div className="page-section">
            Eliminación y cancelación de uso de Cookies
          </div>
          <div className="page-content">
            A continuación se muestra la forma de como eliminar y cancelar el
            registro de cookies para los navegadores de Internet Explorer,
            Mozilla Firefox, Google Chrome y Safari.
          </div>
          <div className="page-content-header">Internet Explorer</div>
          <div className="page-content">
            <span className="page-content-section">
              Eliminar los cookies actualmente almacenados
            </span>
            <ol>
              <li>Inicie el programa Internet Explorer.</li>
              <li>
                Seleccionar en la barra de menú la opción de Herramientas y
                posteriormente la opción de Opciones de Internet.
              </li>
              <li>
                En el apartado de Historial de Navegación se deberá presionar el
                botón de Eliminar.
              </li>
              <li>
                Se abrirá una ventana con varias opciones para eliminar, aquí
                solo se deberá seleccionar (recuadro seleccionado) la opción de
                Cookies.
              </li>
              <li>
                Se presionará el botón de Eliminar, se cerrará automáticamente
                la ventana.
              </li>
              <li>
                Finalmente se debe presionar el botón de OK para finalizar con
                la operación. Toda la información de cookies almacenada se habrá
                eliminado.
              </li>
            </ol>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default PrivacyPage;
