import React from "react";
import "./ownerBanner.css";

import { Link } from "react-router-dom";

const OwnerBanner = (props) => {
  return (
    <div id="owner-banner">
      <div>
        ¿Deseas poner en renta tu propiedad? Da click{" "}
        <Link className="owner-banner-link" to="/publishInfo">
          aquí
        </Link>{" "}
        para publicarlo gratis.
      </div>
      <div className="owner-banner-clickable" onClick={props.onClose}>
        X
      </div>
    </div>
  );
};

export default OwnerBanner;
