import React from "react";
import Popup from 'reactjs-popup';
import "reactjs-popup/dist/index.css"
import "./propertyPopup.css";

import RoundedButton from "../RoundedButton";

const PropertyPopup = (props) => {
  return (
    <Popup 
      modal 
      open={props.open}
      closeOnEscape={false}
      closeOnDocumentClick={false}
      contentStyle={{width: '30vw', borderRadius: '0.7vw'}}
    >
     <div className="popup-container">
      <p onClick={props.onClosePopup} className="popup-cross">X</p>
      <h1 style={{fontSize: "2.1vw"}}>{props.title}</h1>
      <img src={props.img} className="popup-icon"/>
      <p className="popup-text">{props.text}</p>
      <div className="popup-buttons">
        <RoundedButton 
          displayValue="Continuar"
          buttonColor="#0F56BF"
          textColor="white"
          className="continue-button"
          onClick={props.onContinue}
        />
        <RoundedButton 
          displayValue="Cancelar"
          buttonColor="#f7382a"
          textColor="white"
          className="continue-button"
          onClick={props.onClosePopup}
        />
      </div>
     </div>
    </Popup>
  )
};

export default PropertyPopup;

