import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import useScrollBlock from "../../hooks/useScrollBlock";

const ListingModal = ({ isOpen, onClose }) => {
  const [blockScroll, allowScroll] = useScrollBlock();
  const OVERLAY_STYLES = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1000,
  };
  useEffect(() => {
      blockScroll();
  }, [])
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={onClose} />
    </>,
    document.getElementById("portal")
  );
};

export default ListingModal;
