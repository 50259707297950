const getProperties = async (query, pageNumber, limit = 20) => {
  try {
    const url = `${process.env.REACT_APP_SERVER_URL}/properties/filtered`;
    const requestBody = {
      filters: {...query},
      limit,
      pageNumber
    }
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    return res.json();
  } catch (error) {
    console.log(error);
  }
};

export default getProperties;
