import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./header.css";

import NavioLogo from "../../assets/logos/navioWhiteTransparent.png";
import PaddingDiv from "../../components/PaddingDiv";

const Header = (props) => {
  const styles = {
    backgroundColor: props.transparent ? "rgba(255,255,255,0)" : "#112A3E",
  };
  const navigate = useNavigate();
  return (
    <div className="header" style={styles}>
      <PaddingDiv
        height="90%"
        width="98%"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <img
          id="navio-header-logo"
          src={NavioLogo}
          alt="Logo"
          onClick={() => navigate("/")}
        />
        <div id="header-actions-container">
          <div
            onClick={() => navigate("/", { state: { scroll: "benefits" } })}
            className="header-link"
          >
            ¿Por qué Navío?
          </div>
          <div
            onClick={() => navigate("/", { state: { scroll: "tutorial" } })}
            className="header-link"
            to={"/ownerInfo"}
          >
            ¿Cómo funciona?
          </div>
          <Link className="header-link" to={"/publishInfo"}>
            Publicar mi propiedad
          </Link>
        </div>
      </PaddingDiv>
    </div>
  );
};

export default Header;
