import React from "react";
import "./propertyTags.css";

import RoundedButton from "../RoundedButton";

const PropertyTags = (props) => {
  return (
    <div
      id="property-tags"
      className={props.className}
      style={{ display: props.show ? "" : "none" }}
    >
      <RoundedButton
        displayValue={props.property.propertyType === "House" ? "Casa" : "Departamento"}
        fontSize={props.fontSize ? props.fontSize : null}
        className={props.mobile ? "mobile-property-button-primary" :"property-button-primary"}
      />
      {props.property.furnished ? (
        <RoundedButton
          displayValue="Amueblado"
          fontSize={props.fontSize ? props.fontSize : null}
          className={props.mobile ? "mobile-property-button-secondary" : "property-button-secondary"}
        />
      ) : (
        <RoundedButton className="property-button-invisible" />
      )}
    </div>
  );
};

export default PropertyTags;
