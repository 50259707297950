import React, { useState, useEffect } from "react";
import "./mobileTermsAndConditionsPage.css";
import "./termsAndConditionsPage.css";

import Footer from "../../Layouts/Footer";
import Header from "../../Layouts/Header";
import MobileFooter from "../../Layouts/MobileFooter";
import MobileHeader from "../../Layouts/MobileHeader";

const TermsAndConditionsPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    return () => {
      window.removeEventListener("resize", handleWindowChange);
    };
  });
  const isMobile = windowWidth < 500;
  if (isMobile) {
    return (
      <>
        <div id="mobile-terms-and-conditions-page">
          <MobileHeader />
          <div id="mobile-page-title">Términos y Condiciones</div>
          <div id="mobile-page-content">
            JURÍDICO PATRIMONIAL ASI, S.A. DE C.V. (en lo sucesivo, “NAVÍO”)
            hace de su conocimiento sus derechos y obligaciones previstos en la
            Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares, y que será responsabilidad de esta sociedad el uso y
            tratamiento de los mismos. NAVÍO tiene su domicilio social y fiscal
            ubicado en Av. Lázaro Cárdenas 1007, Residencial Santa Bárbara, San
            Pedro Garza García, Nuevo León y nos puede contactar vía correo
            electrónico en: privacidad@navio.mx
          </div>
        </div>
        <MobileFooter />
      </>
    );
  }
  return (
    <>
      <div id="terms-and-conditions-page">
        <Header />
        <div id="page-title">Términos y Condiciones</div>
        <div id="page-content">
          JURÍDICO PATRIMONIAL ASI, S.A. DE C.V. (en lo sucesivo, “NAVÍO”) hace
          de su conocimiento sus derechos y obligaciones previstos en la Ley
          Federal de Protección de Datos Personales en Posesión de los
          Particulares, y que será responsabilidad de esta sociedad el uso y
          tratamiento de los mismos. NAVÍO tiene su domicilio social y fiscal
          ubicado en Av. Lázaro Cárdenas 1007, Residencial Santa Bárbara, San
          Pedro Garza García, Nuevo León y nos puede contactar vía correo
          electrónico en: privacidad@navio.mx
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditionsPage;
