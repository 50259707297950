import React from "react";
import "./mobilePropertyHero.css";

const MobilePropertyHero = (props) => {
  return (
    <div className="mobile-property-hero">
      <div id="mobile-property-hero-title">{props.title}</div>
      {props.icon}
      <div id="mobile-property-hero-desc">{props.desc}</div>
    </div>
  );
};

export default MobilePropertyHero;
