import React from "react";
import "./roundedButton.css";

const RoundedButton = (props) => {
  const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px",
    backgroundColor: props.buttonColor,
    color: props.textColor,
    width: props.width,
    fontFamily: props.fontFamily,
    fontSize: props.fontSize,
    height: props.height,
    filter: props.shadow ? "drop-shadow(0px 5px 4px rgb(160, 160, 160))" : null,
  };
  return (
    <div
      id={props.clickable ? "clickable-rounded-button" : "rounded-button"}
      style={styles}
      onClick={props.onClick}
      className={props.className}
    >
      {props.displayValue}
    </div>
  );
};

export default RoundedButton;
