import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { Carousel } from "react-responsive-carousel";
import "./mobilePropertyPage.css";
import "./propertyPage.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Footer from "../../Layouts/Footer";
import Header from "../../Layouts/Header";
import MobileHeader from "../../Layouts/MobileHeader";
import MobileFooter from "../../Layouts/MobileFooter";
import RoundedButton from "../../components/RoundedButton";
import PropertyTags from "../../components/PropertyTags";
import ListingMap from "../../components/ListingMap";

import { ReactComponent as DoubleBedIcon } from "../../assets/icons/double-bed.svg";
import { ReactComponent as M2Icon } from "../../assets/icons/m2.svg";
import { ReactComponent as ParkingIcon } from "../../assets/icons/estacionamiento.svg";
import { ReactComponent as ShowerIcon } from "../../assets/icons/shower.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/down.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/icons/whatsapp.svg";

import getPropertyByID from "../../service/getPropertyByID";
import translateAmenity from "../../service/common/translateAmenity";

import Armarios from "../../assets/icons/armarios.svg";
import Balcon from "../../assets/icons/balcon.svg";
import Cortinas from "../../assets/icons/cortinas.svg";
import Clima from "../../assets/icons/clima.svg";
import Estufa from "../../assets/icons/estufa.svg";
import Estacionamiento from "../../assets/icons/estacionamiento.svg";
import Horno from "../../assets/icons/horno.svg";
import Jardin from "../../assets/icons/jardin.svg";
import Lavadora from "../../assets/icons/lavadora-inteligente.svg";
import Lavavajillas from "../../assets/icons/lavavajillas.svg";
import Mascotas from "../../assets/icons/mascotas.svg";
import Microondas from "../../assets/icons/microondas.svg";
import Refrigerador from "../../assets/icons/refrigerador.svg";
import Secadora from "../../assets/icons/secadora.svg";
import Seguridad from "../../assets/icons/seguridad.svg";
import PropertyThumbnail from "../../components/PropertyThumbnail";
import PropertyPopup from "../../components/PropertyPopup";
import MobilePropertyPopup from "../../components/MobilePropertyPopup";
import Celebrating from "../../assets/icons/celebrating.svg";
import Schedule from "../../assets/icons/schedule.svg";
import Question from "../../assets/icons/question.svg";

var parse = require("html-react-parser");

const mockAmenities = [
  { name: "Estacionamiento", image: Estacionamiento },
  { name: "Balcón", image: Balcon },
  { name: "Seguridad", image: Seguridad },
  { name: "Jardín", image: Jardin },
  { name: "Mascotas", image: Mascotas },
  { name: "Clima (AC)", image: Clima },
  { name: "Lavadora", image: Lavadora },
  { name: "Secadora", image: Secadora },
  { name: "Cortinas", image: Cortinas },
  { name: "Armarios", image: Armarios },
  { name: "Refrigerador", image: Refrigerador },
  { name: "Estufa", image: Estufa },
  { name: "Horno", image: Horno },
  { name: "Microondas", image: Microondas },
  { name: "Lavavajillas", image: Lavavajillas },
];

const columns = 3;

Modal.setAppElement("#root");

const PropertyPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [property, setProperty] = useState({});
  const [amenityList, setAmenityList] = useState(mockAmenities);
  const [openBookModal, setOpenBookModal] = useState(false);
  const [openVisitModal, setOpenVisitModal] = useState(false);
  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  useEffect(() => {
    getPropertyByID(id).then((fetchedProperty) => {
      fetchedProperty = {
        ...fetchedProperty,
      };
      getAvailableAmenities(fetchedProperty);
      setProperty(fetchedProperty);
    });
  }, []);

  const handleWindowChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    return () => {
      window.removeEventListener("resize", handleWindowChange);
    };
  });

  const getAvailableAmenities = (property) => {
    const propertyAmenities = property.amenities.map((pa) =>
      translateAmenity(pa.name)
    );
    for (let amenity of amenityList) {
      if (propertyAmenities.includes(amenity.name)) {
        amenity.available = true;
      } else {
        amenity.available = false;
      }
    }
  };

  const splitAmenities = (columns) => {
    const itemsPerColumn = Math.floor(amenityList.length / columns);
    const matrix = [];
    for (let i = 0; i < columns; i++) {
      matrix.push(
        amenityList.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn)
      );
    }
    return matrix;
  };

  const openPropertyCarousel = (index) => {
    setOpenImageModal(true);
    setCarouselIndex(index);
  };

  const closePropertyCarousel = () => {
    setOpenImageModal(false);
  };

  const renderPropertyCarouselThumbs = (items) => {
    return items.map((i) => {
      return (
        <img
          src={i.props.children.props.src}
          className="property-page-property-carousel-thumb"
        />
      );
    });
  };

  const isMobile = windowWidth < 500;
  if (isMobile) {
    return (
      <>
        <div id="mobile-property-page">
          <MobileHeader />
          <div
            id="mobile-property-page-back-button-container"
            onClick={() => navigate(-1)}
          >
            <DownArrowIcon id="mobile-property-page-back-icon" />{" "}
            <span id="mobile-property-page-back-text">Regresar a búsqueda</span>
          </div>
          <div id="mobile-property-page-carousel-container">
            <Carousel
              showThumbs={false}
              autoPlay
              infiniteLoop
              dynamicHeight={false}
            >
              {property.images?.map((img, index) => (
                <div key={index} onClick={() => openPropertyCarousel(index)}>
                  <img
                    key={img}
                    src={`${
                      process.env.REACT_APP_SERVER_URL
                    }/properties/images?key=${property._id.toString()}/${img}`}
                    className="mobile-property-page-property-image"
                  />
                </div>
              ))}
            </Carousel>
            <div className="mobile-floating-property-tag">
              <PropertyTags
                mobile
                fontSize={"3.5em"}
                property={property}
                className="mobile-floating-property-tag"
                show={true}
              />
            </div>
          </div>
          <div id="mobile-property-page-property-info-container">
            <div id="mobile-property-page-property-type-container">
              <span>
                {property.propertyType === "House" ? "Casa" : "Departamento"}
              </span>
              <span>ID: {property.propertyNumber}</span>
            </div>
            <div id="mobile-property-page-property-info-first-row">
              <div id="mobile-property-page-property-features">
                <div className="mobile-property-page-property-feature-row">
                  <span id="mobile-property-page-property-price">
                    {property.monthlyRent?.toLocaleString()}
                  </span>
                  <span id="mobile-property-page-monthly-accent">/ mes</span>
                </div>
                <div className="mobile-property-page-property-feature-row">
                  <div id="mobile-property-page-property-municipality">
                    {property.municipality?.name}, NL
                  </div>
                </div>
                <div className="mobile-property-page-property-feature-row">
                  <div id="mobile-property-page-property-street">
                    {property.street}
                  </div>
                </div>
              </div>
              <div id="mobile-property-page-book-button-container">
                <RoundedButton
                  displayValue="Separar Ahora"
                  onClick={() => setOpenBookModal(true)}
                  buttonColor="#0F56BF"
                  fontFamily="SuisseBPIntl-Antique"
                  fontSize="3.5vw"
                  textColor="white"
                  width="100%"
                  height="70%"
                  clickable
                  shadow
                />
              </div>
            </div>
          </div>
          <div id="mobile-property-page-feature-icons-container">
            <div
              className="mobile-property-page-feature-icon-container"
              id="mobile-property-page-double-bed-container"
            >
              <DoubleBedIcon id="mobile-property-page-double-bed-icon" />
              <div style={{ paddingLeft: "25%" }}>{property.numBedrooms}</div>
            </div>
            <div
              className="mobile-property-page-feature-icon-container"
              id="mobile-property-page-shower-container"
            >
              <ShowerIcon id="mobile-property-page-shower-icon" />
              <div style={{ paddingLeft: "25%" }}>{property.numBathrooms}</div>
            </div>
            <div
              className="mobile-property-page-feature-icon-container"
              id="mobile-property-page-m2-container"
            >
              <M2Icon id="mobile-property-page-m2-icon" />
              <div style={{ paddingLeft: "10%" }}>{property.size}m2</div>
            </div>
            <div
              className="mobile-property-page-feature-icon-container"
              id="mobile-property-page-parking-container"
            >
              <ParkingIcon id="mobile-property-page-parking-icon" />
              <div style={{ paddingLeft: "25%" }}>{property.parkingSpots}</div>
            </div>
          </div>
          <div id="mobile-property-page-about-property-label">
            Sobre la Propiedad
          </div>
          <div id="mobile-property-page-about-section">
            {property.description}
          </div>
          <div id="mobile-property-page-feature-buttons">
            <RoundedButton
              displayValue="Agendar Visita Gratuita"
              onClick={() => setOpenVisitModal(true)}
              buttonColor="rgba(15, 86, 191, 0.6)"
              fontFamily="SuisseBPIntl-Antique"
              fontSize="3.3vw"
              textColor="white"
              width="47%"
              height="100%"
              clickable
              shadow
            />
            <RoundedButton
              displayValue="¿Tienes más dudas?"
              onClick={() => setOpenQuestionModal(true)}
              buttonColor="rgba(15, 86, 191, 0.6)"
              fontFamily="SuisseBPIntl-Antique"
              fontSize="3.3vw"
              textColor="white"
              width="47%"
              height="100%"
              clickable
              shadow
            />
          </div>
          <div id="mobile-property-page-characteristics-text">
            Características de la propiedad
          </div>
          <div id="mobile-property-page-row">
            <div id="mobile-property-page-amenities-container">
              {splitAmenities(3).map((column, i) => {
                return (
                  <div className="mobile-property-page-amenity-column" key={i}>
                    {column.map((amenity, j) => {
                      return (
                        <div
                          className="mobile-property-page-amenity"
                          key={amenity.name}
                        >
                          <img
                            src={amenity.image}
                            className="mobile-property-page-amenity-icon"
                          />
                          <div
                            style={{
                              textDecoration: amenity.available
                                ? "none"
                                : "line-through",
                            }}
                          >
                            {amenity.name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          {property.location ? (
            <div className="mobile-property-map-container">
              <ListingMap
                zoom={17}
                labelFontSize="1.3em"
                markers={[
                  {
                    id: property._id,
                    coordinates: property.location?.coordinates,
                    monthlyRent: property.monthlyRent,
                  },
                ]}
              />
            </div>
          ) : null}
          <MobileFooter />
          <Modal
            isOpen={openImageModal}
            onRequestClose={closePropertyCarousel}
            contentLabel="Imagenes"
            style={{
              overlay: { zIndex: 999 },
              content: {
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
              },
            }}
          >
            <Carousel
              selectedItem={carouselIndex}
              showThumbs={false}
              infiniteLoop
            >
              {property.images?.map((img, index) => {
                return (
                  <div key={index}>
                    <img
                      src={`${
                        process.env.REACT_APP_SERVER_URL
                      }/properties/images?key=${property._id.toString()}/${img}`}
                      className="mobile-property-page-property-modal-image"
                    />
                  </div>
                );
              })}
            </Carousel>
          </Modal>
        </div>
        <MobilePropertyPopup
          open={openBookModal}
          title={"¡Ya Casi!"}
          img={Celebrating}
          text={parse(
            "Dando click en el botón de <strong>Continuar </strong>serás redirigido a llenar una breve solicitud."
          )}
          onContinue={() => {
            window.open(
              "https://s9kio07n6ga.typeform.com/to/VszgZOut?typeform-source=app.clickup.com"
            );
            setOpenBookModal(false);
          }}
          onClosePopup={() => {
            setOpenBookModal(false);
          }}
        />
        <MobilePropertyPopup
          open={openVisitModal}
          title={"Agendar Visita"}
          img={Schedule}
          text={parse(
            "Da click en <strong>Continuar</strong> para seleccionar el día y hora de tu preferencia para realizar tu visita."
          )}
          onContinue={() => {
            window.open("https://calendly.com/visita-propiedades");
            setOpenVisitModal(false);
          }}
          onClosePopup={() => {
            setOpenVisitModal(false);
          }}
        />
        <MobilePropertyPopup
          open={openQuestionModal}
          title={"¿Tienes más dudas?"}
          img={Question}
          text={parse(
            "No te preocupes, da click en <strong>Continuar</strong> y uno de nuestros agentes atenderá tus dudas por medio de WhatsApp a la brevedad."
          )}
          onContinue={() => {
            window.open(
              encodeURI(
                `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
              )
            );
            setOpenQuestionModal(false);
          }}
          onClosePopup={() => {
            setOpenQuestionModal(false);
          }}
        />
        <WhatsappIcon
          onClick={() =>
            window.open(
              encodeURI(
                `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
              )
            )
          }
          id="mobile-landing-page-whatsapp-icon"
        />
      </>
    );
  } else {
    return (
      <>
        <div id="property-page">
          <Header />
          <div id="property-page-container">
            <div className="property-column">
              <div
                id="property-page-back-button-container"
                onClick={() => navigate(-1)}
              >
                <DownArrowIcon id="property-page-back-icon" />{" "}
                <span id="property-page-back-text">Regresar a búsqueda</span>
              </div>
              {openImageModal ? null : (
                <Carousel
                  showThumbs={false}
                  selectedItem={carouselIndex}
                  autoPlay
                  infiniteLoop
                  dynamicHeight={false}
                >
                  {property.images?.map((img, index) => (
                    <div
                      key={index}
                      onClick={() => openPropertyCarousel(index)}
                    >
                      <div className="floating-property-tag">
                        <PropertyTags
                          property={property}
                          className="floating-property-tag"
                          show={true}
                        />
                      </div>
                      <img
                        src={`${
                          process.env.REACT_APP_SERVER_URL
                        }/properties/images?key=${property._id.toString()}/${img}`}
                        className="property-page-property-image"
                      />
                    </div>
                  ))}
                </Carousel>
              )}
              <div id="property-page-characteristics-text">
                Características de la propiedad
              </div>
              <div id="property-page-amenities-container">
                {splitAmenities(columns).map((column, i) => {
                  return (
                    <div className="property-page-amenity-column" key={i}>
                      {column.map((amenity, j) => {
                        return (
                          <div
                            className="property-page-amenity"
                            key={amenity.name}
                          >
                            <img
                              src={amenity.image}
                              className="property-page-amenity-icon"
                            />
                            <p
                              style={{
                                textDecoration: amenity.available
                                  ? "none"
                                  : "line-through",
                              }}
                            >
                              {amenity.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="property-column">
              <div id="property-page-property-info-container">
                <div id="property-page-property-type-container">
                  <span>
                    {" "}
                    {property.propertyType === "House"
                      ? "Casa"
                      : "Departamento"}
                  </span>
                  <span>ID: {property.propertyNumber}</span>
                </div>
                <div id="property-page-property-info-first-row">
                  <div id="property-page-property-features">
                    <div className="property-page-property-feature-row">
                      <span id="property-page-property-price">
                        {property.monthlyRent?.toLocaleString()}
                      </span>
                      <span id="property-page-monthly-accent">/ mes</span>
                    </div>
                    <div className="property-page-property-feature-row">
                      <div id="property-page-property-municipality">
                        {property.municipality?.name}, NL
                      </div>
                    </div>
                    <div className="property-page-property-feature-row">
                      <div id="property-page-property-street">
                        {property.street}
                      </div>
                    </div>
                    <div id="property-page-feature-icon-container">
                      <div
                        className="property-page-detail-group"
                        id="property-page-double-bed-container"
                      >
                        <DoubleBedIcon id="property-page-double-bed-icon" />
                        <div style={{ paddingLeft: "30%" }}>
                          {property.numBedrooms}
                        </div>
                      </div>
                      <div
                        className="property-page-detail-group"
                        id="property-page-shower-container"
                      >
                        <ShowerIcon id="property-page-shower-icon" />
                        <div style={{ paddingLeft: "20%" }}>
                          {property.numBathrooms}
                        </div>
                      </div>
                      <div
                        className="property-page-detail-group"
                        id="property-page-m2-container"
                      >
                        <M2Icon id="property-page-m2-icon" />
                        <div style={{ paddingLeft: "10%" }}>
                          {property.size}m2
                        </div>
                      </div>
                      <div
                        className="property-page-detail-group"
                        id="property-page-parking-container"
                      >
                        <ParkingIcon id="property-page-parking-icon" />
                        <div style={{ paddingLeft: "30%" }}>
                          {property.parkingSpots}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="property-page-book-button-container">
                    <RoundedButton
                      displayValue="Separar Ahora"
                      onClick={() => setOpenBookModal(true)}
                      buttonColor="#0F56BF"
                      fontFamily="SuisseBPIntl-Antique"
                      fontSize="1.1vw"
                      textColor="white"
                      width="70%"
                      height="40%"
                      clickable
                    />
                  </div>
                </div>
                <div id="property-page-about-property-label">
                  Sobre la Propiedad
                </div>
                <div id="property-page-about-section">
                  {property.description}
                </div>
                <div id="property-page-feature-buttons">
                  <RoundedButton
                    displayValue="Agendar Visita Gratuita"
                    onClick={() => setOpenVisitModal(true)}
                    buttonColor="rgba(15, 86, 191, 0.6)"
                    fontFamily="SuisseBPIntl-Antique"
                    fontSize="1.1vw"
                    textColor="white"
                    width="45%"
                    height="90%"
                    clickable
                  />
                  <RoundedButton
                    displayValue="¿Tienes más dudas?"
                    onClick={() => setOpenQuestionModal(true)}
                    buttonColor="rgba(15, 86, 191, 0.6)"
                    fontFamily="SuisseBPIntl-Antique"
                    fontSize="1.1vw"
                    textColor="white"
                    width="45%"
                    height="90%"
                    clickable
                  />
                </div>
              </div>
              {property.location ? (
                <div className="property-map-container">
                  <ListingMap
                    zoom={19}
                    markers={[
                      {
                        id: property._id,
                        coordinates: property.location?.coordinates,
                        monthlyRent: property.monthlyRent,
                      },
                    ]}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
        <Modal
          isOpen={openImageModal}
          onRequestClose={closePropertyCarousel}
          contentLabel="Imagenes"
          style={{ content: { overflow: "hidden" } }}
        >
          <Carousel
            selectedItem={carouselIndex}
            showThumbs={false}
            infiniteLoop
          >
            {property.images?.map((img, index) => {
              return (
                <div key={index}>
                  <img
                    src={`${
                      process.env.REACT_APP_SERVER_URL
                    }/properties/images?key=${property._id.toString()}/${img}`}
                    className="property-page-property-modal-image"
                  />
                </div>
              );
            })}
          </Carousel>
        </Modal>
        <PropertyPopup
          open={openBookModal}
          title={"¡Ya Casi!"}
          img={Celebrating}
          text={parse(
            "Dando click en el botón de <strong>Continuar </strong>serás redirigido a llenar una breve solicitud."
          )}
          onContinue={() => {
            window.open(
              "https://s9kio07n6ga.typeform.com/to/VszgZOut?typeform-source=app.clickup.com"
            );
            setOpenBookModal(false);
          }}
          onClosePopup={() => {
            setOpenBookModal(false);
          }}
        />
        <PropertyPopup
          open={openVisitModal}
          title={"Agendar Visita"}
          img={Schedule}
          text={parse(
            "Da click en <strong>Continuar</strong> para seleccionar el día y hora de tu preferencia para realizar tu visita."
          )}
          onContinue={() => {
            window.open("https://calendly.com/visita-propiedades");
            setOpenVisitModal(false);
          }}
          onClosePopup={() => {
            setOpenVisitModal(false);
          }}
        />
        <PropertyPopup
          open={openQuestionModal}
          title={"¿Tienes más dudas?"}
          img={Question}
          text={parse(
            "No te preocupes, da click en <strong>Continuar</strong> y uno de nuestros agentes atenderá tus dudas por medio de WhatsApp a la brevedad."
          )}
          onContinue={() => {
            window.open(
              encodeURI(
                `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
              )
            );
            setOpenQuestionModal(false);
          }}
          onClosePopup={() => {
            setOpenQuestionModal(false);
          }}
        />
        <WhatsappIcon
          onClick={() =>
            window.open(
              encodeURI(
                `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
              )
            )
          }
          id="property-page-whatsapp-icon"
        />
      </>
    );
  }
};

// const PropertyPage = () => {
//   const [property, setProperty] = useState(mockProperty);
//   const [similarProperties, setSimilarProperties] = useState([]);
//   const [amenities, setAmenities] = useState(mockAmenities);
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [openBookModal, setOpenBookModal] = useState(false);
//   const [openVisitModal, setOpenVisitModal] = useState(false);
//   const [openQuestionModal, setOpenQuestionModal] = useState(false);
//   const [primaryCarouselIndex, setPrimaryCarouselIndex] = useState(0);
//   const navigate = useNavigate();
//   const { id } = useParams();

//   // Get property by ID.
//   useEffect(() => {
//     getPropertyByID(id).then((fecthedProperty) => {
//       setProperty(fecthedProperty);
//     })
//   }, []);

//   const splitAmenities = (columns) => {
//     let itemsPerColumn = Math.floor(amenities.length / columns);
//     let matrix = [];

//     for (let i = 0; i < columns; i++) {
//       matrix.push(
//         amenities.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn)
//       );
//     }

//     return matrix;
//   };

//   const getAvailableAmenities = () => {
//     let amenityAvailability = [];

//     for (let a of amenities) {
//       let available = false;
//       for (const pa of property.amenities) {
//         if (pa.name === a.name) {
//           available = true;
//         }
//       }
//       a.available = available;
//     }
//     setAmenities(amenities);
//     return amenityAvailability;
//   };

//   const getSimilarProperties = (num) => {
//     let array = [];
//     mockProperty.image = mockProperty.images[0];
//     for (let i = 0; i < num; i++) {
//       array.push(mockProperty);
//     }

//     setSimilarProperties(array);
//   };

//   const openModal = (index, item) => {
//     console.log(index);
//     setPrimaryCarouselIndex(index);
//     setModalIsOpen(true);
//   };

//   const afterOpenModal = () => {
//     // Nada
//   };

//   const closeModal = () => {
//     setModalIsOpen(false);
//   };

// const renderModalCarouselThumbs = (items) => {
//   return items.map((i) => {
//     return (
//       <img
//         src={i.props.children.props.src}
//         className="property-thumbnail thumb-img"
//       />
//     );
//   });
// };

//   useEffect(() => {
//     getAvailableAmenities();
//     getSimilarProperties(7);
//   }, []);

//   return (
//     <div className="propertyPage">
//       <Header />
//       <div className="property-container">
// <Modal
//   isOpen={modalIsOpen}
//   onAfterOpen={afterOpenModal}
//   onRequestClose={closeModal}
//   contentLabel="Imagenes"
//   style={{ zIndex: 999 }}
// >
//   <Carousel
//     showThumbs={true}
//     selectedItem={primaryCarouselIndex}
//     renderThumbs={renderModalCarouselThumbs}
//     thumbWidth={"10vw"}
//   >
//     {property.images?.map((img) => {
//       return (
//         <div>
//           <img
//             src={img}
//             className="property-thumbnail modal-img"
//             onClick={openModal}
//           />
//         </div>
//       );
//     })}
//   </Carousel>
// </Modal>
//         <div className="property-column">
//           <div className="property-subtitle">
//             <p
//               className="back-subtitle"
//               onClick={() => {
//                 navigate(-1);
//               }}
//             >
//               {"<"} Regresar a propiedades
//             </p>
//           </div>
//           {modalIsOpen ? null : (
//             <Carousel
//               showThumbs={false}
//               onClickItem={openModal}
//               selectedItem={primaryCarouselIndex}
//             >
//               {property.images?.map((img) => {
//                 return (
//                   <div>
//                     <img
//                       src={img}
//                       className="property-thumbnail big-img"
//                       onClick={openModal}
//                     />
//                   </div>
//                 );
//               })}
//             </Carousel>
//           )}
//           <div className="floating-property-tag">
//             <PropertyTags
//               property={property}
//               className="floating-property-tag"
//               show={!modalIsOpen}
//             />
//           </div>
//           <h2 className="property-h2">Características de la propiedad</h2>
//           <div className="amenities-container">
//             {splitAmenities(columns).map((column, i) => {
//               return (
//                 <div className="amenity-column">
//                   {column.map((e, j) => {
//                     return (
//                       <div className="amenity">
//                         <img src={e.image} className="amenity-icon" />
//                         <p
//                           style={{
//                             textDecoration: e.available
//                               ? "none"
//                               : "line-through",
//                           }}
//                         >
//                           {e.name}
//                         </p>
//                       </div>
//                     );
//                   })}
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//         <div className="property-column" style={{ marginTop: "2vw" }}>
//           <p style={{ fontSize: "1vw" }}>
//             {property.type == "house" ? "Casa" : "Departamento"}
//           </p>
//           <div className="feature-container">
//             <div className="property-column-inside">
//               <div className="property-price">
//                 ${property.monthlyRent?.toLocaleString()}
//                 <span id="thumbnail-property-monthly-rent-month">/ mes</span>
//               </div>
//               <div className="property-municipality">
//                 {property.municipality?.name}
//               </div>
//               <div className="property-street">{property.street}</div>
//               <div id="thumbnail-property-detail">
//                 <div className="thumbnail-property-detail-group">
//                   <DoubleBedIcon width="25%" height="100%" />
//                   {property.numBedrooms}
//                 </div>
//                 <div className="thumbnail-property-detail-group">
//                   <ShowerIcon width="25%" />
//                   {property.numBathrooms}
//                 </div>
//                 <div className="thumbnail-property-detail-group">
//                   <M2Icon width="25%" height="100%" />
//                   {property.size} m2
//                 </div>
//                 <div className="thumbnail-property-detail-group">
//                   <ParkingIcon width="25%" />
//                   {property.parkingSpots}
//                 </div>
//               </div>
//             </div>
//             <div className="property-column-smaller">
//               <RoundedButton
//                 displayValue="Separar Ahora"
//                 buttonColor="#0F56BF"
//                 textColor="white"
//                 clickable
//                 className="book-button"
//                 onClick={() => {
//                   setOpenBookModal(true);
//                 }}
//               />
//               <PropertyPopup
//                 open={openBookModal}
//                 title={"¡Ya Casi!"}
//                 img={Celebrating}
//                 text={parse(
//                   "Dando click en el botón de <strong>Continuar</strong> serás atendido personalmente por uno de nuestros agentes, quien te ayudará a finalizar tu proceso."
//                 )}
//                 onClosePopup={() => {
//                   setOpenBookModal(false);
//                 }}
//               />
//             </div>
//           </div>
//           <h3 style={{ fontSize: "1.1vw" }}>Sobre la propiedad</h3>
//           <p style={{ fontSize: "0.9vw" }}>{property.description}</p>
//           <div className="feature-container">
//             <div className="property-column-inside">
//               <RoundedButton
//                 displayValue="Agendar Visita Gratuita"
//                 buttonColor="#79a8f2"
//                 textColor="white"
//                 clickable
//                 className="more-info-button"
//                 onClick={() => {
//                   setOpenVisitModal(true);
//                 }}
//               />
//               <PropertyPopup
//                 open={openVisitModal}
//                 title={"Agendar Visita"}
//                 img={Schedule}
//                 text={parse(
//                   "Da click en <strong>Continuar</strong> para que uno de nuestros agentes se asegure de agendar tu visita en el día y hora de tu preferencia."
//                 )}
//                 onClosePopup={() => {
//                   setOpenVisitModal(false);
//                 }}
//               />
//             </div>
//             <div className="property-column-inside">
//               <RoundedButton
//                 displayValue="¿Tienes más dudas?"
//                 buttonColor="#79a8f2"
//                 textColor="white"
//                 clickable
//                 className="more-info-button"
//                 onClick={() => setOpenQuestionModal(true)}
//               />
//               <PropertyPopup
//                 open={openQuestionModal}
//                 title={"¿Tienes más dudas?"}
//                 img={Question}
//                 text={parse(
//                   "No te preocupes, da click en <strong>Continuar</strong> y uno de nuestros agentes atenderá tus dudas por medio de WhatsApp a la brevedad."
//                 )}
//                 onClosePopup={() => {
//                   setOpenQuestionModal(false);
//                 }}
//               />
//             </div>
//           </div>
//           <div className="property-map-container">
//             <ListingMap
//               markers={[{
//                 id: property.id,
//                 coordinates: property.location?.coordinates
//               }]}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="similar-properties">
//         <h2 style={{ fontSize: "1.6vw" }}>Propiedades similares</h2>
//         <div className="similar-container">
//           {similarProperties.map((p) => {
//             return (
//               <PropertyThumbnail
//                 property={p}
//                 width="100%"
//                 height="22vw"
//                 className="similar-thumbnail"
//                 minWidth="27vw"
//               />
//             );
//           })}
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

export default PropertyPage;
