import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./mobilePublishPropertyPage.css";
import "./publishPropertyPage.css";

import { Carousel } from "react-responsive-carousel";
import Header from "../../Layouts/Header";
import MobileHeader from "../../Layouts/MobileHeader";
import MobileFooter from "../../Layouts/MobileFooter";
import Footer from "../../Layouts/Footer";
import PropertyHero from "../../components/PropertyHero";
import MobilePropertyHero from "../../components/MobilePropertyHero";
import RoundedButton from "../../components/RoundedButton";

import { ReactComponent as PositiveVote } from "../../assets/icons/positive-vote.svg";
import { ReactComponent as DebitCard } from "../../assets/icons/debit-card.svg";
import { ReactComponent as Relief } from "../../assets/icons/relief.svg";
import { ReactComponent as MagnifyingGlass } from "../../assets/icons/magnifying-glass.svg";
import { ReactComponent as Shield } from "../../assets/icons/shield.svg";

const heros = [
  {
    title: "Acompañamiento Total",
    icon: <PositiveVote height="40%" />,
    desc: "Desde que anunciamos tu inmueble en el portal y agendar visitas, hasta que se firme y concluya el contrato.",
  },
  {
    title: "Aseguramos tu pago mensual",
    icon: <DebitCard height="40%" />,
    desc: "Si el inquilino no paga, no te preocupes, Navío cubre con la renta mensual para no afectar tu liquidez.",
  },
  {
    title: "Respaldo de Inquilinos",
    icon: <Relief height="40%" />,
    desc: "Si tu inquilino seleccionado por Navío abandona el inmueble antes de los primeros 6 meses, te conseguimos uno nuevo gratis.",
  },
  {
    title: "Desalojamos al inquilino por incumplimiento",
    icon: <MagnifyingGlass height="40%" />,
    desc: "Nos encargamos de desalojar al inquilino por cualquier incumplimiento de su contrato.",
  },
  {
    title: "Protegemos tu seguridad y propiedad",
    icon: <Shield height="40%" />,
    desc: "Evaluamos a fondo a tu posible inquilino y aprobamos únicamente a los mejores inquilinos para tu propiedad.",
  },
];

const PublishPropertyPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleWindowChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    return () => {
      window.removeEventListener("resize", handleWindowChange);
    };
  });
  const isMobile = windowWidth < 500;
  if (isMobile) {
    return (
      <div id="mobile-publish-property-page">
        <MobileHeader />
        <div id="mobile-publish-property-title">Publicar mi propiedad</div>
        <div id="mobile-publish-property-page-carousel-container">
          <Carousel showThumbs={false} showStatus={false} autoPlay interval={6000} infiniteLoop>
            {heros.map((h) => {
              return (
                <div>
                  <MobilePropertyHero
                    title={h.title}
                    desc={h.desc}
                    icon={h.icon}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
        <div id="mobile-publish-info-text">
          Déjanos tus datos y publica tu propiedad hoy mismo...{" "}
          <span>¡Es gratis!</span>
        </div>
        <div id="mobile-publish-property-form">
          <label className="mobile-publish-form-label">
            Nombre(s)<span>*</span>
          </label>
          <input className="mobile-publish-form-input" type="text" />
          <label className="mobile-publish-form-label">
            Apellido(s)<span>*</span>
          </label>
          <input className="mobile-publish-form-input" type="text" />
          <label className="mobile-publish-form-label">
            Teléfono<span>*</span>
          </label>
          <input className="mobile-publish-form-input" type="text" />
          <label className="mobile-publish-form-label">
            Correo electrónico<span>*</span>
          </label>
          <input className="mobile-publish-form-input" type="text" />
          <div id="mobile-publish-form-agreement">
            Al dar clic en el botón aceptas los{" "}
            <Link to="/terms" target="_blank">Términos y Condiciones</Link> y el{" "}
            <Link to="/privacy" target="_blank">Aviso de Privacidad</Link> de Navío.
          </div>
          <RoundedButton
            displayValue={"Continuar"}
            buttonColor={"#112A3E"}
            textColor={"white"}
            fontFamily={"SuisseBPIntl-Antique"}
            fontSize={"1.2em"}
            height="70px"
            width="60%"
            clickable
          />
          <div id="mobile-property-promo">
            Sin costos adicionales, sin deducibles, y sin letras chiquitas
          </div>
          <MobileFooter />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div id="publish-property-page">
          <Header />
          <div id="publish-property-title">Publicar mi propiedad</div>
          <div id="publish-property-content-container">
            <div className="publish-property-column-container">
              <Carousel
                showThumbs={false}
                showStatus={false}
                autoPlay
                infiniteLoop
              >
                {heros.map((h) => {
                  return (
                    <div>
                      <PropertyHero
                        title={h.title}
                        desc={h.desc}
                        icon={h.icon}
                      />
                    </div>
                  );
                })}
              </Carousel>
              <div id="property-promo">
                Sin costos adicionales, sin deducibles, y sin letras chiquitas
              </div>
            </div>
            <div className="publish-property-column-container">
              <div id="publish-info-text">
                Déjanos tus datos y publica tu propiedad hoy mismo...{" "}
                <span>¡Es gratis!</span>
              </div>
              <div id="publish-property-form">
                <label className="publish-form-label">
                  Nombre(s)<span>*</span>
                </label>
                <input className="publish-form-input" type="text" />
                <label className="publish-form-label">
                  Apellido(s)<span>*</span>
                </label>
                <input className="publish-form-input" type="text" />
                <label className="publish-form-label">
                  Teléfono<span>*</span>
                </label>
                <input className="publish-form-input" type="text" />
                <label className="publish-form-label">
                  Correo electrónico<span>*</span>
                </label>
                <input className="publish-form-input" type="text" />
                <div id="publish-form-agreement">
                  Al dar clic en el botón aceptas los{" "}
                  <Link to="/terms" target="_blank">Términos y Condiciones</Link> y el{" "}
                  <Link to="/privacy" target="_blank">Aviso de Privacidad</Link> de Navío.
                </div>
                <RoundedButton
                  displayValue={"Continuar"}
                  buttonColor={"#112A3E"}
                  textColor={"white"}
                  fontFamily={"SuisseBPIntl-Antique"}
                  fontSize={"1.14vw"}
                  height="13%"
                  width="30%"
                  clickable
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
};

export default PublishPropertyPage;
