const getMunicipalities = async () => {
    try {
        const url = `${process.env.REACT_APP_SERVER_URL}/municipalities/all`;
        const res = await fetch(url);
        return res.json();
    } catch (error) {
        console.log(error);
    }
};

export default getMunicipalities;