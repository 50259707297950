import React, { useState, useEffect } from "react";
import chunk from "lodash/chunk";
import "./moreFiltersModal.css";

import ModalSelect from "../ModalSelect";
import RoundedButton from "../RoundedButton";

const MoreFiltersModal = ({ open, onClose, query, setQuery, mobile }) => {
  const [localQuery, setLocalQuery] = useState(query);
  useEffect(() => {
    if (localQuery !== query) {
      setLocalQuery(query);
    }
  }, [query]);
  const updateLocalQuery = (filter, value) => {
    setLocalQuery(
      JSON.parse(
        JSON.stringify({
          ...localQuery,
          [filter]: value,
        })
      )
    );
  };
  // Clears all fields.
  const clearSearch = () => {
    setLocalQuery(
      JSON.parse(
        JSON.stringify({
          ...localQuery,
          amenities: [],
          numBathrooms: null,
          numBedrooms: null,
          parkingSpots: null,
          propertyType: null,
          parkingSpots: null,
          monthlyRent: {
            min: null,
            max: null,
          },
          size: {
            min: null,
            max: null,
          },
        })
      )
    );
  };
  // Syncing the original query with the local query.
  const syncQuery = () => {
    setQuery(localQuery);
  };

  const setPropertyType = (type) => updateLocalQuery("propertyType", type);
  const equalsPropertyType = (type) => localQuery.propertyType === type;
  const setNumBathrooms = (num) => updateLocalQuery("numBathrooms", num);
  const equalsNumBathrooms = (num) => localQuery.numBathrooms === num;
  const setNumBedrooms = (num) => updateLocalQuery("numBedrooms", num);
  const equalsNumBedrooms = (num) => localQuery.numBedrooms === num;
  const setParkingSpots = (num) => updateLocalQuery("parkingSpots", num);
  const equalsParkingSpots = (num) => localQuery.parkingSpots === num;

  const toggleAmenities = (amenity) => {
    if (localQuery.amenities.includes(amenity)) {
      updateLocalQuery(
        "amenities",
        localQuery.amenities.filter((x) => x != amenity)
      );
    } else {
      updateLocalQuery("amenities", [...localQuery.amenities, amenity]);
    }
  };
  const containsAmenity = (amenity) => localQuery.amenities.includes(amenity);

  const getInputValueFromQuery = (field) => {
    if (field === null) {
      return "";
    } else {
      return field;
    }
  };

  const largeWebModalSelectWidth = "10.2vw";
  const smallWebModalSelectWidth = "4vw";
  const largeMobileModalSelectWidth = "27.7vw";
  const smallMobileModalSelectWidth = "16.35vw";

  const typeOptions = [
    {
      option: "Casa",
      selected: equalsPropertyType("House"),
      onClick: () => setPropertyType("House"),
    },
    {
      option: "Departamento",
      selected: equalsPropertyType("Apartment"),
      onClick: () => setPropertyType("Apartment"),
    },
    {
      option: "Ambos",
      selected: equalsPropertyType(null),
      onClick: () => setPropertyType(null),
    },
  ];

  const bathroomOptions = [
    {
      option: "1+",
      selected: equalsNumBathrooms(1),
      onClick: () => setNumBathrooms(1),
    },
    {
      option: "2+",
      selected: equalsNumBathrooms(2),
      onClick: () => setNumBathrooms(2),
    },
    {
      option: "3+",
      selected: equalsNumBathrooms(3),
      onClick: () => setNumBathrooms(3),
    },
    {
      option: "4+",
      selected: equalsNumBathrooms(4),
      onClick: () => setNumBathrooms(4),
    },
    {
      option: "5+",
      selected: equalsNumBathrooms(5),
      onClick: () => setNumBathrooms(5),
    },
  ];

  const bedroomOptions = [
    {
      option: "1+",
      selected: equalsNumBedrooms(1),
      onClick: () => setNumBedrooms(1),
    },
    {
      option: "2+",
      selected: equalsNumBedrooms(2),
      onClick: () => setNumBedrooms(2),
    },
    {
      option: "3+",
      selected: equalsNumBedrooms(3),
      onClick: () => setNumBedrooms(3),
    },
    {
      option: "4+",
      selected: equalsNumBedrooms(4),
      onClick: () => setNumBedrooms(4),
    },
    {
      option: "5+",
      selected: equalsNumBedrooms(5),
      onClick: () => setNumBedrooms(5),
    },
  ];

  const parkingOptions = [
    {
      option: "1+",
      selected: equalsParkingSpots(1),
      onClick: () => setParkingSpots(1),
    },
    {
      option: "2+",
      selected: equalsParkingSpots(2),
      onClick: () => setParkingSpots(2),
    },
    {
      option: "3+",
      selected: equalsParkingSpots(3),
      onClick: () => setParkingSpots(3),
    },
    {
      option: "4+",
      selected: equalsParkingSpots(4),
      onClick: () => setParkingSpots(4),
    },
    {
      option: "5+",
      selected: equalsParkingSpots(5),
      onClick: () => setParkingSpots(5),
    },
  ];

  const generalAmenitiesOptions = [
    {
      option: "Balcón",
      selected: containsAmenity("Balcony"),
      onClick: () => toggleAmenities("Balcony"),
    },
    {
      option: "Seguridad",
      selected: containsAmenity("Security"),
      onClick: () => toggleAmenities("Security"),
    },
    {
      option: "Jardín",
      selected: containsAmenity("Garden"),
      onClick: () => toggleAmenities("Garden"),
    },
    {
      option: "Mascotas",
      selected: containsAmenity("Pet Friendly"),
      onClick: () => toggleAmenities("Pet Friendly"),
    },
  ];

  const interiorAmenitiesOptions = [
    {
      option: "Clima (AC)",
      selected: containsAmenity("Air Conditioning"),
      onClick: () => toggleAmenities("Air Conditioning"),
    },
    {
      option: "Lavadora",
      selected: containsAmenity("Washing Machine"),
      onClick: () => toggleAmenities("Washing Machine"),
    },
    {
      option: "Secadora",
      selected: containsAmenity("Dryer"),
      onClick: () => toggleAmenities("Dryer"),
    },
    {
      option: "Refrigerador",
      selected: containsAmenity("Fridge"),
      onClick: () => toggleAmenities("Fridge"),
    },
    {
      option: "Estufa",
      selected: containsAmenity("Stove"),
      onClick: () => toggleAmenities("Stove"),
    },
    {
      option: "Horno",
      selected: containsAmenity("Oven"),
      onClick: () => toggleAmenities("Oven"),
    },
    {
      option: "Microondas",
      selected: containsAmenity("Microwave"),
      onClick: () => toggleAmenities("Microwave"),
    },
    {
      option: "Lavavajillas",
      selected: containsAmenity("Dishwasher"),
      onClick: () => toggleAmenities("Dishwasher"),
    },
    {
      option: "Cortinas",
      selected: containsAmenity("Curtains"),
      onClick: () => toggleAmenities("Curtains"),
    },
    {
      option: "Armarios",
      selected: containsAmenity("Wardrobes"),
      onClick: () => toggleAmenities("Wardrobes"),
    },
  ];

  const produceSelectRows = (collection, numberColumns, selectWidth) => {
    const rows = chunk(collection, numberColumns);
    return rows.map((row) => {
      return (
        <div className="more-filters-modal-row">
          {row.map((element) => {
            return (
              <ModalSelect
                displayValue={element.option}
                onClick={element.onClick}
                selected={element.selected}
                width={selectWidth}
                mobile={mobile}
              />
            );
          })}
        </div>
      );
    });
  };

  if (!open) return null;
  return (
    <>
      {mobile ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            zIndex: 1000,
          }}
        />
      ) : null}
      <div id={mobile ? "mobile-more-filters-modal" : "more-filters-modal"}>
        <div
          style={{
            fontSize: mobile ? "4vw" : "1.4vw",
            height: mobile ? "6%" : "10%",
            minHeight: mobile ? "6%" : "10%",
          }}
          id="more-filters-modal-title-section"
        >
          <div /> <div>Más filtros</div>{" "}
          <div id="more-filters-modal-close" onClick={onClose}>
            X
          </div>
        </div>
        <div id="more-filters-modal-filter-container">
          {/* Price Section */}
          <div className="more-filters-modal-section">
            <div
              className="more-filters-modal-section-title"
              style={{ fontSize: mobile ? "3.8vw" : "1.4vw" }}
            >
              Precio
            </div>
            <div
              className={`${
                mobile ? "mobile-" : ""
              }more-filters-modal-numeric-row`}
            >
              <input
                className={`${
                  mobile ? "mobile-" : ""
                }more-filters-modal-text-input`}
                placeholder="Mínimo"
                type="number"
                value={getInputValueFromQuery(localQuery.monthlyRent.min)}
                onChange={(e) => {
                  setLocalQuery((currQuery) => ({
                    ...currQuery,
                    monthlyRent: {
                      min: e.target.value,
                      max: currQuery.monthlyRent.max,
                    },
                  }));
                }}
              />
              -
              <input
                className={`${
                  mobile ? "mobile-" : ""
                }more-filters-modal-text-input`}
                placeholder="Máximo"
                type="number"
                value={getInputValueFromQuery(localQuery.monthlyRent.max)}
                onChange={(e) => {
                  setLocalQuery((currQuery) => ({
                    ...currQuery,
                    monthlyRent: {
                      min: currQuery.monthlyRent.min,
                      max: e.target.value,
                    },
                  }));
                }}
              />
              <div
                className="more-filters-modal-clear-button"
                style={{ fontSize: mobile ? "3vw" : "1vw" }}
                onClick={() =>
                  setLocalQuery((currQuery) => ({
                    ...currQuery,
                    monthlyRent: {
                      min: null,
                      max: null,
                    },
                  }))
                }
              >
                Borrar
              </div>
            </div>
          </div>
          {/* Type Section */}
          <div className="more-filters-modal-section">
            <div
              className="more-filters-modal-section-title"
              style={{ fontSize: mobile ? "3.8vw" : "1.4vw" }}
            >
              Tipo
            </div>
            {produceSelectRows(
              typeOptions,
              3,
              mobile ? largeMobileModalSelectWidth : largeWebModalSelectWidth
            )}
          </div>
          {/* Bathroom Section */}
          <div className="more-filters-modal-section">
            <div
              className="more-filters-modal-section-title"
              style={{ fontSize: mobile ? "3.8vw" : "1.4vw" }}
            >
              Baños
            </div>
            {produceSelectRows(
              bathroomOptions,
              5,
              mobile ? smallMobileModalSelectWidth : smallWebModalSelectWidth
            )}
          </div>
          {/* Bedroom Section */}
          <div className="more-filters-modal-section">
            <div
              className="more-filters-modal-section-title"
              style={{ fontSize: mobile ? "3.8vw" : "1.4vw" }}
            >
              Cuartos
            </div>
            {produceSelectRows(
              bedroomOptions,
              5,
              mobile ? smallMobileModalSelectWidth : smallWebModalSelectWidth
            )}
          </div>
          {/* Property Size Section */}
          <div className="more-filters-modal-section">
            <div
              className="more-filters-modal-section-title"
              style={{ fontSize: mobile ? "3.8vw" : "1.4vw" }}
            >
              Área m2
            </div>
            <div
              className={`${
                mobile ? "mobile-" : ""
              }more-filters-modal-numeric-row`}
            >
              <input
                className={`${
                  mobile ? "mobile-" : ""
                }more-filters-modal-text-input`}
                placeholder="Mínimo"
                type="number"
                value={getInputValueFromQuery(localQuery.size.min)}
                onChange={(e) => {
                  setLocalQuery((currQuery) => ({
                    ...currQuery,
                    size: {
                      min: e.target.value,
                      max: currQuery.size.max,
                    },
                  }));
                }}
              />
              -
              <input
                className={`${
                  mobile ? "mobile-" : ""
                }more-filters-modal-text-input`}
                placeholder="Máximo"
                type="number"
                value={getInputValueFromQuery(localQuery.size.max)}
                onChange={(e) => {
                  setLocalQuery((currQuery) => ({
                    ...currQuery,
                    size: {
                      min: currQuery.size.min,
                      max: e.target.value,
                    },
                  }));
                }}
              />
              <div
                className="more-filters-modal-clear-button"
                style={{ fontSize: mobile ? "3vw" : "1vw" }}
                onClick={() => {
                  setLocalQuery((currQuery) => ({
                    ...currQuery,
                    size: {
                      min: null,
                      max: null,
                    },
                  }));
                }}
              >
                Borrar
              </div>
            </div>
          </div>
          {/* Parking Section */}
          <div className="more-filters-modal-section">
            <div
              className="more-filters-modal-section-title"
              style={{ fontSize: mobile ? "3.8vw" : "1.4vw" }}
            >
              Estacionamiento
            </div>
            {produceSelectRows(
              parkingOptions,
              5,
              mobile ? smallMobileModalSelectWidth : smallWebModalSelectWidth
            )}
          </div>
          <div className="more-filters-modal-section">
            <div
              className="more-filters-modal-section-title"
              style={{ fontSize: mobile ? "3.8vw" : "1.4vw" }}
            >
              Características Generales
            </div>
            {mobile
              ? produceSelectRows(
                  generalAmenitiesOptions,
                  3,
                  largeMobileModalSelectWidth
                )
              : produceSelectRows(
                  generalAmenitiesOptions,
                  4,
                  largeWebModalSelectWidth
                )}
          </div>
          <div className="more-filters-modal-section-last">
            <div
              className="more-filters-modal-section-title"
              style={{ fontSize: mobile ? "3.8vw" : "1.4vw" }}
            >
              Características de Interior
            </div>
            {mobile
              ? produceSelectRows(
                  interiorAmenitiesOptions,
                  3,
                  largeMobileModalSelectWidth
                )
              : produceSelectRows(
                  interiorAmenitiesOptions,
                  5,
                  largeWebModalSelectWidth
                )}
          </div>
        </div>
        <div id="more-filters-modal-footer">
          <div
            onClick={clearSearch}
            className="more-filters-modal-clear-button"
            style={{ fontSize: mobile ? "3vw" : "1vw" }}
          >
            Limpiar búsqueda
          </div>
          <RoundedButton
            onClick={() => {
              syncQuery();
              onClose();
            }}
            clickable
            displayValue="Buscar"
            buttonColor={"#0F56BF"}
            textColor={"white"}
            fontFamily="SuisseBPIntl-Antique"
            fontSize={mobile ? "4vw" : null}
            width={mobile ? "30%" : "15%"}
            height="60%"
          />
        </div>
      </div>
    </>
  );
};

export default MoreFiltersModal;
