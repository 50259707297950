import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Fonts.
import "./fonts/SuisseBPIntl-Antique.otf";
import "./fonts/SuisseBPIntl-Bold.otf";
import "./fonts/SuisseBPIntl-Light.otf";
import "./fonts/SuisseBPIntl-Medium.otf";
import "./fonts/SuisseBPIntl-Regular.otf";
import "./fonts/SuisseBPIntl-RegularItalic.otf";
import "./fonts/SuisseBPIntl-Thin.otf";
import "./fonts/SuisseBPIntl-ThinItalic.otf";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
