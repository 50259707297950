import React from "react";
import "./propertyThumbnail.css";

import PropertyTags from "../PropertyTags";

import { ReactComponent as DoubleBedIcon } from "../../assets/icons/double-bed.svg";
import { ReactComponent as M2Icon } from "../../assets/icons/m2.svg";
import { ReactComponent as ParkingIcon } from "../../assets/icons/estacionamiento.svg";
import { ReactComponent as ShowerIcon } from "../../assets/icons/shower.svg";
import { useNavigate } from "react-router-dom";

const PropertyThumbnail = (props) => {
  const navigate = useNavigate();
  const { property } = props;
  const styles = {
    width: props.width,
    height: props.height,
    minWidth: props.minWidth,
  };

  return (
    <div
      id="property-thumbnail"
      className={props.className}
      style={styles}
      onClick={() => {
        navigate(`/property/${property._id}`);
      }}
    >
      <img
        id="thumbnail-property-image"
        src={`${process.env.REACT_APP_SERVER_URL}/properties/images/?key=${property._id.toString()}/${property.images[0]}`}
        alt="property-image"
      />
      <PropertyTags mobile={props.mobile} property={property} show={true} />
      <div id="thumbnail-property-monthly-rent">
        ${property.monthlyRent.toLocaleString()}
        <span id="thumbnail-property-monthly-rent-month">/ mes</span>
      </div>
      <div id="thumbnail-property-municipality">{`${property.municipality.name}, Nuevo León`}</div>
      
      <div id="thumbnail-property-street">{property.street}</div>
      <div id="thumbnail-property-detail">
        <div className="thumbnail-property-detail-group">
          <DoubleBedIcon width="25%" height="100%" />
          {property.numBedrooms}
        </div>
        <div className="thumbnail-property-detail-group">
          <ShowerIcon width="25%" />
          {property.numBathrooms}
        </div>
        <div className="thumbnail-property-detail-group">
          <M2Icon width="25%" height="100%" />
          {property.size} m2
        </div>
        <div className="thumbnail-property-detail-group">
          <ParkingIcon width="25%" />
          {property.parkingSpots}
        </div>
      </div>
    </div>
  );
};

export default PropertyThumbnail;
