import React from "react";
import { Link } from "react-router-dom";
import "./mobileFooter.css";

const footerSections = [
  {
    title: "Servicios",
    links: [
      {
        title: "Rentar propiedad",
        route: "/listing",
        blankTarget: false,
      },
      {
        title: "Publicar propiedad",
        route: "/publishInfo",
        blankTarget: false,
      },
    ],
  },
  {
    title: "Legal",
    links: [
      {
        title: "Términos y Condiciones",
        route: "/terms",
        blankTarget: true,
      },
      {
        title: "Aviso de Privacidad",
        route: "/privacy",
        blankTarget: true,
      },
      {
        title: "Preguntas Frecuentes",
        route: "/faq",
        blankTarget: true,
      },
    ],
  },
];

const MobileFooter = () => {
  return (
    <div id="mobile-footer">
      {footerSections.map((section) => {
        return (
          <div className="mobile-footer-section" key={section.title}>
            <div className="mobile-footer-section-title">{section.title}</div>
            {section.links.map((l) => {
              return (
                <div key={l.title}>
                  <Link
                    to={l.route}
                    target={l.blankTarget ? "_blank" : "_self"}
                    className="mobile-footer-text clickable"
                  >
                    {l.title}
                  </Link>
                </div>
              );
            })}
          </div>
        );
      })}
      <div id="mobile-footer-copyright">© 2020 Navio S.A. de C.V.</div>
    </div>
  );
};

export default MobileFooter;
