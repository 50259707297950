import React from "react";

const PaddingDiv = (props) => {
  const styles = {
    display: "flex",
    flexDirection: props.flexDirection,
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
    height: props.height,
    width: props.width,
  };

  return <div style={styles}>{props.children}</div>;
};

export default PaddingDiv;