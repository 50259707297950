import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import FaqPage from "../Pages/FaqPage";
import LandingPage from "../Pages/LandingPage";
import ListingPage from "../Pages/ListingPage";
import PrivacyPage from "../Pages/PrivacyPage";
import PropertyPage from "../Pages/PropertyPage";
import PublishPropertyPage from "../Pages/PublishPropertyPage";
import TermsAndConditionsPage from "../Pages/TermsAndConditionsPage";

import "../App.css";

const MainRouter = () => {
  return (
    <BrowserRouter>
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/listing" element={<ListingPage />} />
            <Route path="/property/:id" element={<PropertyPage />} />
            <Route path="/terms" element={<TermsAndConditionsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/publishInfo" element={<PublishPropertyPage />} />
          </Routes>
        </div>
    </BrowserRouter>
  );
};

export default MainRouter;
