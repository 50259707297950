import React from "react";
import "./landingPageHero.css";

import RoundedButton from "../RoundedButton";

const LandingPageHero = ({ title, icon, subtitle, desc }) => {
  return (
    <div className="landing-page-hero">
      <RoundedButton
        buttonColor="#112A3E"
        textColor="white"
        height="11%"
        width="70%"
        displayValue={title}
        fontFamily="SuisseBpIntl-Medium"
        fontSize="6vw"
      />
      {icon}
      <div id="landing-page-hero-catchphrase">{subtitle}</div>
      <div id="landing-page-hero-desc">{desc}</div>
    </div>
  );
};

export default LandingPageHero;
