import React, { useState } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { getCenter } from "geolib";
import groupBy from "lodash/groupBy";
import flatten from "lodash/flatten";
import "./listingMap.css";

import ListingModal from "../ListingModal/listingModal";

import globo from "../../assets/icons/globoMapa.svg";
import globoMultiple from "../../assets/icons/globoMultiple.svg";

import mapStyles from "./mapStyles";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  gestureHandling: "greedy",
  zoomControl: true,
};

const ListingMap = (props) => {
  const [showListingModal, setShowListingModal] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  if (loadError) return "Error Loading Maps";
  if (!isLoaded) return "Loading Maps";

  const centerCoords = getCenter(
    props.markers?.map((marker) => {
      return {
        latitude: marker.coordinates[1],
        longitude: marker.coordinates[0],
      };
    })
  );

  const groupedMarkers = props.markers
    ? groupBy(
        props.markers,
        (marker) => `${marker.coordinates[1]}+${marker.coordinates[0]}`
      )
    : {};
  const priceMarkers = flatten(
    Object.values(groupedMarkers).filter((value) => value.length == 1)
  );
  const jointMarkers = Object.values(groupedMarkers).filter(
    (value) => value.length > 1
  );

  const mapCenter = centerCoords
    ? { lat: centerCoords.latitude, lng: centerCoords.longitude }
    : { lat: 0, lng: 0 };
  return (
    <>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={props.zoom}
        center={mapCenter}
        options={options}
      >
        {priceMarkers.map((marker) => (
          <Marker
            key={marker.id}
            icon={{ url: globo, labelOrigin: { x: 60.5, y: 16.5 } }}
            label={{
              text: `$${marker.monthlyRent.toLocaleString()}`,
              color: "white",
              fontSize: props.labelFontSize,
              fontFamily: "SuisseBPIntl-Antique",
            }}
            position={{
              lat: marker.coordinates[1],
              lng: marker.coordinates[0],
            }}
          />
        ))}
        {jointMarkers.map((properties, index) => (
          <Marker
            key={index}
            icon={{ url: globoMultiple, labelOrigin: { x: 27.2, y: 16.5 } }}
            label={{
              text: `${properties.length}`,
              color: "white",
              fontSize: props.labelFontSize,
              fontFamily: "SuisseBPIntl-Antique",
            }}
            onClick={() => setShowListingModal(true)}
            position={{
              lat: properties[0].coordinates[1],
              lng: properties[0].coordinates[0],
            }}
          />
        ))}
      </GoogleMap>
      <ListingModal isOpen={showListingModal} onClose={() =>setShowListingModal(false)}/>
    </>
  );
};

export default ListingMap;
