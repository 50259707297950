import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

import PaddingDiv from "../../components/PaddingDiv";

const Footer = () => {
  return (
    <div id="footer">
      <PaddingDiv height="80%" width="90%">
        <div className="footer-column">
          <div className="footer-text">Servicios</div>
          <br />
          <Link to="/publishInfo" className="footer-text clickable">
            Publicar propiedad
          </Link>
          <Link to="/listing" className="footer-text clickable">
            Rentar propiedad
          </Link>
          <div id="footer-copyright">© 2020 Navio S.A. de C.V.</div>
        </div>
        <div className="footer-column">
          <div className="footer-text">Legal</div>
          <br />
          <Link to="/terms" target="_blank" className="footer-text clickable">
            Términos y Condiciones
          </Link>
          <Link to="/privacy" target="_blank" className="footer-text clickable">
            Aviso de Privacidad
          </Link>
          <Link to="/faq" target="_blank" className="footer-text clickable">
            Preguntas Frecuentes
          </Link>
        </div>
      </PaddingDiv>
    </div>
  );
};

export default Footer;
