const getPropertyByID = async (id) => {
    try {
        const url = `${process.env.REACT_APP_SERVER_URL}/properties/${id}`;
        const res = await fetch(url);
        return res.json();
    } catch (error) {
        console.log(error);
    }
};

export default getPropertyByID;