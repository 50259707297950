import React from "react";
import ReactDom from "react-dom";

const HamburgerModal = ({ children, isOpen, onClose }) => {
  const MODAL_STYLES = {
    position: "fixed",
    backgroundColor: "white",
    top: "90px",
    right: "0%",
    zIndex: 1000,
  };
  const OVERLAY_STYLES = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 1000,
  };
  if (!isOpen) return null;
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLES} onClick={onClose} />
      <div style={MODAL_STYLES}>{children}</div>
    </>,
    document.getElementById("portal")
  );
};

export default HamburgerModal;
