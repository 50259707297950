import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "./mobileLandingPage.css";
import "./landingPage.css";

import DropdownBubble from "../../components/DropdownBubble";
import Footer from "../../Layouts/Footer";
import Header from "../../Layouts/Header";
import MobileHeader from "../../Layouts/MobileHeader";
import MobileFooter from "../../Layouts/MobileFooter";
import OwnerBanner from "../../components/OwnerBanner";
import RoundedButton from "../../components/RoundedButton";
import LandingPageHero from "../../components/LandingPageHero";

import { ReactComponent as DollarIcon } from "../../assets/icons/dollar.svg";
import { ReactComponent as DoubleBedIcon } from "../../assets/icons/double-bed.svg";
import { ReactComponent as HandshakeIcon } from "../../assets/icons/handshake.svg";
import { ReactComponent as KeyIcon } from "../../assets/icons/libertad.svg";
import { ReactComponent as PositiveThinkingIcon } from "../../assets/icons/positive-thinking.svg";
import { ReactComponent as RocketIcon } from "../../assets/icons/rapidez.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/icons/whatsapp.svg";

import MtyBanner from "../../assets/BannerMTY.jpeg";

import getMunicipalities from "../../service/getMunicipalities";

const locationDefaultValue = { value: null, label: "Ubicación" };
const budgetDefaultValue = { value: null, label: "Presupuesto Mensual" };
const roomDefaultValue = { value: null, label: "Cuartos" };
const budgetOptions = [
  { value: { min: 0, max: 15000 }, label: "Hasta $15,000 MXN" },
  { value: { min: 0, max: 20000 }, label: "Hasta $20,000 MXN" },
  { value: { min: 0, max: 25000 }, label: "Hasta $25,000 MXN" },
  { value: { min: 25000, max: null }, label: "Más de $25,000 MXN" },
  { value: { min: undefined, max: undefined }, label: "No tengo presupuesto" },
];
const roomOptions = [
  { value: 1, label: "1+" },
  { value: 2, label: "2+" },
  { value: 3, label: "3+" },
  { value: 4, label: "4+" },
  { value: undefined, label: "Sin restricciones" },
];

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const getDropdownOptions = (items) => {
  const options = [];
  for (const item of items) {
    options.push({ value: item._id, label: `${item.name}, NL` });
  }
  return options;
};

const landingHeros = [
  {
    title: "Libertad",
    icon: <KeyIcon height="40%" width="60%" />,
    subtitle: "Con Navío, solo firmas tú y ¡listo!",
    desc: "Porque confiamos en ti, renta la propiedad de tus sueños sin la necesidad de avales o fiadores.",
  },
  {
    title: "Rapidez",
    icon: <RocketIcon height="40%" width="35%" />,
    subtitle: "¡Sabemos que tu tiempo vale oro!",
    desc: "Con Navío olvídate de trámites tediosos y burocráticos ya que en cuestión de minutos damos respuesta a tu solicitud.",
  },
  {
    title: "Transparencia",
    icon: <HandshakeIcon height="40%" width="50%" />,
    subtitle: "¡Cuentas claras, amistades largas!",
    desc: "Lo que ves en nuestra plataforma es lo que es, no hay pagos adicionales, ni sorpresas.",
  },
];

const navioSteps = [
  {
    step: 1,
    title: "Aplica filtros",
    desc: "Selecciona los que más se adecuen a lo que estás buscando",
  },
  {
    step: 2,
    title: "Navega y elige",
    desc: "Ve las propiedades y escoge la que más te guste",
  },
  {
    step: 3,
    title: "Aparta tu próximo hogar",
    desc: "Agenda tu visita o separa en linea con atención personalizada",
  },
];

const LandingPage = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [showBanner, setShowBanner] = useState(true);

  const [municipality, setMunicipality] = useState(null);
  const [monthlyRent, setMonthlyRent] = useState(null);
  const [numBedrooms, setNumBedrooms] = useState(null);

  const [municipalityOptions, setMunicipalityOptions] = useState([]);

  const handleWindowChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    return () => {
      window.removeEventListener("resize", handleWindowChange);
    };
  }, []);

  useEffect(() => {
    getMunicipalities().then((fetchedMunicipalities) => {
      setMunicipalityOptions(getDropdownOptions(fetchedMunicipalities));
    });
  }, []);

  const navigate = useNavigate();
  const { state } = useLocation();
  const benefits = useRef(null);
  const scrollToBenefits = () => scrollToRef(benefits);
  const tutorial = useRef(null);
  const scrollToTutorial = () => scrollToRef(tutorial);

  useEffect(() => {
    if (state && state.scroll === "benefits") {
      scrollToBenefits();
    }
    if (state && state.scroll === "tutorial") {
      scrollToTutorial();
    }
  });

  const isMobile = windowWidth < 744;
  if (isMobile) {
    return (
      <>
        <div className="mobile-landing-page-main">
          <MobileHeader />
          <div id="mobile-landing-page-main-content">
            <div id="mobile-landing-page-search-section">
              <div id="mobile-landing-page-catchphrase">
                Rentar sin aval nunca había sido tan{" "}
                <span id="mobile-landing-page-catchphrase-accent">fácil</span>
              </div>
              <DropdownBubble
                height="12%"
                width="90%"
                icon={<SearchIcon width="10%" />}
                defaultValue={locationDefaultValue}
                options={municipalityOptions}
                shadow={true}
                controlFontFamily="SuisseBPIntl-Thin"
                optionFontFamily="SuisseBPIntl-Thin"
                onChange={setMunicipality}
                padded
                fontSize="4vw"
              />
              <div className="mobile-landing-page-bubble-padding" />
              <DropdownBubble
                height="12%"
                width="90%"
                icon={<DollarIcon width="10%" />}
                defaultValue={budgetDefaultValue}
                options={budgetOptions}
                shadow={true}
                controlFontFamily="SuisseBPIntl-Thin"
                optionFontFamily="SuisseBPIntl-Thin"
                onChange={setMonthlyRent}
                padded
                fontSize="4vw"
              />
              <div className="mobile-landing-page-bubble-padding" />
              <DropdownBubble
                height="12%"
                width="90%"
                icon={<DoubleBedIcon width="10%" />}
                defaultValue={roomDefaultValue}
                options={roomOptions}
                shadow={true}
                controlFontFamily="SuisseBPIntl-Thin"
                optionFontFamily="SuisseBPIntl-Thin"
                onChange={setNumBedrooms}
                padded
                fontSize="4vw"
              />
              <div className="mobile-landing-page-bubble-padding" />
              <RoundedButton
                buttonColor="#5B81A6"
                textColor="White"
                fontFamily="SuisseBpIntl-Bold"
                fontSize="4vw"
                height="10%"
                width="50%"
                displayValue={"Buscar"}
                clickable={true}
                onClick={() =>
                  navigate("/listing", {
                    state: { municipality, monthlyRent, numBedrooms },
                  })
                }
              />
              <div id="mobile-search-section-owner-text">
                Si eres propietario, <br /> anuncia tu inmueble gratis{" "}
                <span
                  className="info-link"
                  onClick={() => navigate(`/publishInfo`)}
                >
                  aquí
                </span>
              </div>
            </div>
            <img id="mobile-landing-page-city-banner" src={MtyBanner} />
          </div>
        </div>
        <div id="mobile-landing-page-tenant-info-section" ref={benefits}>
          <div className="mobile-landing-page-section-title">
            ¿Por qué Navío?
          </div>
          <div id="mobile-landing-page-carousel-container">
            <Carousel
              showThumbs={false}
              showStatus={false}
              autoPlay
              interval={5500}
              infiniteLoop
            >
              {landingHeros.map((h) => {
                return (
                  <div key={h.title}>
                    <LandingPageHero
                      title={h.title}
                      subtitle={h.subtitle}
                      desc={h.desc}
                      icon={h.icon}
                    />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
        <div id="mobile-landing-page-tenant-info-section" ref={tutorial}>
          <div className="mobile-landing-page-section-title">
            ¿Cómo funciona?
          </div>
          <div id="mobile-landing-page-functions-container">
            {navioSteps.map((step) => {
              return (
                <div className="mobile-landing-page-navio-step" key={step.step}>
                  <RoundedButton
                    buttonColor="#112A3E"
                    textColor="white"
                    displayValue={step.step}
                    fontFamily="SuisseBPIntl-Medium"
                    fontSize="5vw"
                    width="18%"
                    height="30%"
                  />
                  <div className="mobile-landing-page-navio-step-title">
                    {step.title}
                  </div>
                  <div className="mobile-landing-page-navio-step-desc">
                    {step.desc}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div id="mobile-landing-page-assosiate-message">
          Si quieres hablar con un asesor, da click{" "}
          <span
            onClick={() =>
              window.open(
                encodeURI(
                  `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
                )
              )
            }
          >
            aquí
          </span>
        </div>
        <MobileFooter />
        <WhatsappIcon
          onClick={() =>
            window.open(
              encodeURI(
                `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
              )
            )
          }
          id="mobile-landing-page-whatsapp-icon"
        />
      </>
    );
  } else {
    return (
      <>
        <div id="landing-page">
          <div id="main-content">
            <Header showLandingOptions tutorialClick={scrollToTutorial} />
            <div id="search-section">
              <div id="landing-page-catchphrase">
                Rentar{" "}
                <span className="landing-page-catchphrase-accent">
                  sin aval
                </span>{" "}
                nunca <br /> había sido tan{" "}
                <span className="landing-page-catchphrase-accent">fácil</span>
              </div>
              <div id="search-bubbles-container">
                <DropdownBubble
                  height="100%"
                  width="26%"
                  icon={<SearchIcon width="10%" />}
                  defaultValue={locationDefaultValue}
                  options={municipalityOptions}
                  shadow={true}
                  controlFontFamily="SuisseBPIntl-Thin"
                  optionFontFamily="SuisseBPIntl-Thin"
                  onChange={setMunicipality}
                  padded
                  fontSize="1.2vw"
                />
                <DropdownBubble
                  height="100%"
                  width="26%"
                  icon={<DollarIcon width="10%" />}
                  defaultValue={budgetDefaultValue}
                  options={budgetOptions}
                  shadow={true}
                  controlFontFamily="SuisseBPIntl-Thin"
                  optionFontFamily="SuisseBPIntl-Thin"
                  onChange={setMonthlyRent}
                  padded
                  fontSize="1.2vw"
                />
                <DropdownBubble
                  height="100%"
                  width="26%"
                  icon={<DoubleBedIcon width="10%" />}
                  defaultValue={roomDefaultValue}
                  options={roomOptions}
                  shadow={true}
                  controlFontFamily="SuisseBPIntl-Thin"
                  optionFontFamily="SuisseBPIntl-Thin"
                  onChange={setNumBedrooms}
                  padded
                  fontSize="1.2vw"
                />
                <RoundedButton
                  buttonColor="#5B81A6"
                  textColor="White"
                  fontFamily="SuisseBpIntl-Bold"
                  fontSize="1.14vw"
                  height="90%"
                  width="15%"
                  displayValue={"Buscar"}
                  clickable={true}
                  onClick={() =>
                    navigate("/listing", {
                      state: { municipality, monthlyRent, numBedrooms },
                    })
                  }
                />
              </div>
              <div id="search-section-owner-text">
                Si eres propietario, anuncia tu inmueble gratis{" "}
                <span
                  className="info-link"
                  onClick={() => navigate(`/publishInfo`)}
                >
                  aquí
                </span>
              </div>
            </div>
            <img id="landing-page-city-banner" src={MtyBanner} />
          </div>
          <div id="tenant-info-section" ref={benefits}>
            <div className="landing-page-section-title">¿Por qué Navío?</div>
            <div id="tenant-info-container">
              <div className="navio-info-value">
                <RoundedButton
                  buttonColor="#112A3E"
                  textColor="white"
                  height="15%"
                  width="90%"
                  displayValue="Libertad"
                  fontFamily="SuisseBpIntl-Medium"
                  fontSize="2vw"
                />
                <div className="navio-info-value-container">
                  <KeyIcon className="navio-info-value-icon" />
                </div>
                <div className="navio-info-value-catchphrase">
                  Con Navío, solo firmas tú y ¡listo!
                </div>
                <div className="navio-info-value-text">
                  Porque confiamos en <br /> ti, renta la propiedad <br /> de
                  tus sueños sin la <br /> necesidad de avales <br /> o fiadores
                </div>
              </div>
              <div className="navio-info-value">
                <RoundedButton
                  buttonColor="#112A3E"
                  textColor="white"
                  height="15%"
                  width="90%"
                  displayValue="Rapidez"
                  fontFamily="SuisseBpIntl-Medium"
                  fontSize="2vw"
                />
                <div className="navio-info-value-container">
                  <RocketIcon
                    className="navio-info-value-icon"
                    id="navio-info-value-rocket-icon"
                  />
                </div>
                <div className="navio-info-value-catchphrase">
                  ¡Sabemos que tu tiempo vale oro!
                </div>
                <div className="navio-info-value-text">
                  Con Navío olvídate de <br /> trámites tediosos y <br />{" "}
                  burocráticos ya que en <br /> cuestión de minutos damos <br />{" "}
                  respuesta a tu solicitud
                </div>
              </div>
              <div className="navio-info-value">
                <RoundedButton
                  buttonColor="#112A3E"
                  textColor="white"
                  height="15%"
                  width="90%"
                  displayValue="Transparencia"
                  fontFamily="SuisseBpIntl-Medium"
                  fontSize="2vw"
                />
                <div className="navio-info-value-container">
                  <HandshakeIcon className="navio-info-value-icon" />
                </div>
                <div className="navio-info-value-catchphrase">
                  ¡Cuentas claras, amistades largas!
                </div>
                <div className="navio-info-value-text">
                  Lo que ves en nuestra plataforma es lo que es, <br /> no hay
                  pagos <br />
                  adicionales, ni <br /> sorpresas
                </div>
              </div>
            </div>
          </div>
          <div ref={tutorial} id="tenant-tutorial-section">
            <div className="landing-page-section-title">¿Cómo funciona?</div>
            <div id="tenant-tutorial-container">
              <div id="tenant-tutorial">
                <div className="tenant-tutorial-point">
                  <RoundedButton
                    buttonColor="#112A3E"
                    textColor="white"
                    displayValue="1"
                    fontFamily="SuisseBPIntl-Medium"
                    fontSize="3vw"
                    className="tenant-tutorial-rounded-button"
                  />
                  <div className="tenant-tutorial-point-text-container">
                    <div className="tenant-tutorial-main-text">
                      Aplica filtros
                    </div>
                    <div className="tenant-tutorial-secondary-text">
                      Selecciona los que más se adecúen a lo que estás buscando
                    </div>
                  </div>
                </div>
                <div className="tenant-tutorial-point">
                  <RoundedButton
                    buttonColor="#112A3E"
                    textColor="white"
                    displayValue="2"
                    fontFamily="SuisseBPIntl-Medium"
                    fontSize="3vw"
                    className="tenant-tutorial-rounded-button"
                  />
                  <div className="tenant-tutorial-point-text-container">
                    <div className="tenant-tutorial-main-text">
                      Navega y elige
                    </div>
                    <div className="tenant-tutorial-secondary-text">
                      Ve las propiedades y escoge la que más te guste
                    </div>
                  </div>
                </div>
                <div className="tenant-tutorial-point">
                  <RoundedButton
                    buttonColor="#112A3E"
                    textColor="white"
                    displayValue="3"
                    fontFamily="SuisseBPIntl-Medium"
                    fontSize="3vw"
                    className="tenant-tutorial-rounded-button"
                  />
                  <div className="tenant-tutorial-point-text-container">
                    <div className="tenant-tutorial-main-text">
                      Aparta tu próximo hogar
                    </div>
                    <div className="tenant-tutorial-secondary-text">
                      Agenda tu visita o separa en linea con atención
                      personalizada
                    </div>
                  </div>
                </div>
              </div>
              <div id="tenant-tutorial-icon">
                <PositiveThinkingIcon width="100%" height="100%" />
              </div>
            </div>
            <div id="tenant-tutorial-more-info-text">
              Si quieres hablar con un asesor, da click{" "}
              <Link id="tenant-tutorial-more-info-text-accent" to="/tenant">
                aquí
              </Link>
            </div>
          </div>
        </div>
        <Footer />
        <WhatsappIcon
          onClick={() =>
            window.open(
              encodeURI(
                `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
              )
            )
          }
          id="landing-page-whatsapp-icon"
        />
        {showBanner ? <OwnerBanner onClose={() => setShowBanner(false)}/> : null}
      </>
    );
  }
};

export default LandingPage;
