import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./listingPage.css";
import "./mobileListingPage.css";

import { ReactComponent as AddButtonIcon } from "../../assets/icons/add-button.svg";
import { ReactComponent as DoubleBedIcon } from "../../assets/icons/double-bed.svg";
import { ReactComponent as DollarIcon } from "../../assets/icons/dollar.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as ShowerIcon } from "../../assets/icons/shower.svg";
import { ReactComponent as TypeIcon } from "../../assets/icons/tipo.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/icons/whatsapp.svg";

import Footer from "../../Layouts/Footer";
import Header from "../../Layouts/Header";
import MobileHeader from "../../Layouts/MobileHeader";
import MobileFooter from "../../Layouts/MobileFooter";
import MoreFiltersModal from "../../components/MoreFiltersModal";
import ListingMap from "../../components/ListingMap";
import PropertyThumbnail from "../../components/PropertyThumbnail";
import MobilePropertyThumbnail from "../../components/MobilePropertyThumbnail";
import DropdownBubble from "../../components/DropdownBubble";

import getMunicipalities from "../../service/getMunicipalities";
import getProperties from "../../service/getProperties";
import RoundedButton from "../../components/RoundedButton";

// Dropdown bubble default values.
const locationDefaultValue = { value: null, label: "Ubicación" };
const priceDefaultValue = { value: null, label: "Precio" };
const bedroomDefaultValue = { value: null, label: "Cuartos" };
const bathroomDefaultValue = { value: null, label: "Baños" };
const typeDefaultValue = { value: null, label: "Tipo" };

const budgetOptions = [
  { value: { min: null, max: 15000 }, label: "Hasta $15,000 MXN" },
  { value: { min: null, max: 20000 }, label: "Hasta $20,000 MXN" },
  { value: { min: null, max: 25000 }, label: "Hasta $25,000 MXN" },
  { value: { min: 25000, max: null }, label: "Más de $25,000 MXN" },
  { value: { min: undefined, max: undefined }, label: "No tengo presupuesto" },
];
const roomOptions = [
  { value: 1, label: "1+" },
  { value: 2, label: "2+" },
  { value: 3, label: "3+" },
  { value: 4, label: "4+" },
  { value: 0, label: "Sin restricciones" },
];
const bathroomOptions = [
  { value: 1, label: "1+" },
  { value: 2, label: "2+" },
  { value: 3, label: "3+" },
  { value: 4, label: "4+" },
  { value: 5, label: "5+" },
];
const typeOptions = [
  { value: "House", label: "Casa" },
  { value: "Apartment", label: "Departamento" },
  { value: null, label: "Ambos" },
];

const getSingleColumnThumbnails = (properties) => {
  if (properties.length) {
    return properties.map((p) => (
      <div key={p._id} className="mobile-listing-page-thumbnail">
        <MobilePropertyThumbnail
          height="100%"
          width="100%"
          property={p}
          mobile
        />
      </div>
    ));
  }
};

const formatThumbnails = (properties) => {
  const thumbnailGroups = [];
  for (let i = 0; i < properties.length; i += 2) {
    if (i === properties.length - 1) {
      thumbnailGroups.push([properties[i]]);
    } else {
      thumbnailGroups.push([properties[i], properties[i + 1]]);
    }
  }
  const produce = (group, index) => {
    return (
      <div className="thumbnail-row" key={index}>
        {group.map((p) => (
          <PropertyThumbnail
            key={p._id}
            width="49%"
            height="100%"
            property={p}
            mobile={false}
          />
        ))}
      </div>
    );
  };
  return thumbnailGroups.map(produce);
};

const getDropdownOptions = (items) => {
  const options = [];
  for (const item of items) {
    options.push({ value: item._id, label: `${item.name}, NL` });
  }
  return options;
};

const ListingPage = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [showMoreFiltersModal, setShowMoreFiltersModal] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [query, setQuery] = useState({
    municipality: state ? state.municipality?.value : null,
    monthlyRent: {
      min: state ? state.monthlyRent?.value?.min : null,
      max: state ? state.monthlyRent?.value?.max : null,
    },
    size: {
      min: null,
      max: null,
    },
    numBedrooms: state ? state.numBedrooms?.value : null,
    numBathrooms: null,
    propertyType: null,
    parkingSpots: null,
    amenities: [],
  });
  const [properties, setProperties] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [municipalityOptions, setMunicipalityOptions] = useState([]);

  const thumbnailStart = useRef(null);

  const handleWindowChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowChange);
    return () => {
      window.removeEventListener("resize", handleWindowChange);
    };
  }, []);

  useEffect(() => {
    if (pageLoading) {
      getProperties(query, pageNumber, itemsPerPage).then((response) => {
        setProperties(response.results);
        setTotalResults(response.pagination.count);
        setTotalPages(response.pagination.totalPages);
        setTimeout(() => {
          setPageLoading(false);
        }, 2000);
      });
    }
  }, [pageLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
    thumbnailStart.current.scrollTo(0, 0);
    setPageLoading(true);
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(0);
    setPageLoading(true);
  }, [query]);

  useEffect(() => {
    getMunicipalities().then((fetchedMunicipalities) => {
      setMunicipalityOptions(getDropdownOptions(fetchedMunicipalities));
    });
  }, []);

  // useEffect(() => {
  //   if (showMoreFiltersModal) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "unset";
  //   }
  // }, [showMoreFiltersModal]);

  const resetQuery = () => {
    setQuery({
      municipality: null,
      monthlyRent: {
        min: null,
        max: null,
      },
      size: {
        min: null,
        max: null,
      },
      numBedrooms: null,
      numBathrooms: null,
      propertyType: null,
      parkingSpots: null,
      amenities: [],
    });
  };

  const getDropdownOptionFromValue = (options, value, defaultValue) => {
    return options.find((v) => v.value === value) || defaultValue;
  };

  const getPriceDropdownOption = () => {
    const foundOption = budgetOptions.find((b) => {
      return (
        b.value.min === query.monthlyRent.min &&
        b.value.max === query.monthlyRent.max
      );
    });
    let customOption;
    if (query.monthlyRent.max !== null) {
      customOption = {
        value: { min: query.monthlyRent.min, max: query.monthlyRent.max },
        label: `Hasta $${query.monthlyRent.max}MXN`,
      };
    } else {
      customOption = null;
    }
    return foundOption || customOption || priceDefaultValue;
  };

  const updateQuery = (field, value) => {
    setQuery((currQuery) => ({
      ...currQuery,
      [field]: value,
    }));
  };

  const currentPageGreaterThanZero = () => {
    return pageNumber > 0;
  };

  const currentPageLessThanMax = () => {
    return pageNumber < totalPages - 1;
  };

  const currentPageInLimit = () => {
    return (
      (currentPageGreaterThanZero() && !currentPageLessThanMax()) ||
      (!currentPageGreaterThanZero() && currentPageLessThanMax())
    );
  };

  const isMobile = windowWidth < 500;
  if (isMobile) {
    return (
      <>
        <div id="mobile-listing-page">
          <MobileHeader />
          <div id="mobile-listing-page-filter-section">
            <DropdownBubble
              height="70%"
              width="73%"
              icon={<SearchIcon width="10%" height="70%" />}
              value={getDropdownOptionFromValue(
                municipalityOptions,
                query.municipality,
                locationDefaultValue
              )}
              options={municipalityOptions}
              shadow={false}
              controlFontFamily="SuisseBPIntl-Regular"
              optionFontFamily="SuisseBPIntl-Thin"
              onChange={(n) => updateQuery("municipality", n.value)}
              border
              fontSize="3.1vw"
            />
            <div
              id="mobile-listing-page-more-filters"
              onClick={() => setShowMoreFiltersModal(true)}
            >
              Más Filtros
              <AddButtonIcon width="20%" height="70%" />
            </div>
          </div>
          <div id="mobile-thumbnail-list-section" ref={thumbnailStart}>
            <div id="mobile-listing-section-headers">
              <div id="mobile-total-properties-header">
                Propiedades en Renta ({totalResults})
              </div>
            </div>
            <div id="mobile-thumbnail-list">
              {getSingleColumnThumbnails(properties)}
              <div
                id="mobile-listing-page-navigation-buttons"
                style={{
                  justifyContent: currentPageInLimit()
                    ? "center"
                    : "space-between",
                }}
              >
                {currentPageGreaterThanZero() ? (
                  <RoundedButton
                    onClick={() =>
                      setPageNumber((currNumber) => currNumber - 1)
                    }
                    displayValue="Página anterior"
                    buttonColor="#0F56BF"
                    fontFamily="SuisseBPIntl-Bold"
                    fontSize="2.2vw"
                    textColor="white"
                    clickable
                    height="8vw"
                    width="25vw"
                  />
                ) : null}
                {currentPageLessThanMax() ? (
                  <RoundedButton
                    onClick={() =>
                      setPageNumber((currNumber) => currNumber + 1)
                    }
                    displayValue="Siguiente página"
                    buttonColor="#0F56BF"
                    fontFamily="SuisseBPIntl-Bold"
                    fontSize="2.2vw"
                    textColor="white"
                    clickable
                    height="8vw"
                    width="25vw"
                  />
                ) : null}
              </div>
              <div id="mobile-listing-page-navigation-text">
                Mostrando {pageNumber * itemsPerPage + 1}-
                {Math.min(
                  pageNumber * itemsPerPage + itemsPerPage,
                  totalResults
                )}{" "}
                de {totalResults}
              </div>
            </div>
            <MobileFooter />
          </div>
        </div>
        <MoreFiltersModal
          mobile
          open={showMoreFiltersModal}
          onClose={() => setShowMoreFiltersModal(false)}
          query={query}
          setQuery={setQuery}
        />
        <WhatsappIcon
          onClick={() =>
            window.open(
              encodeURI(
                `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
              )
            )
          }
          id="mobile-landing-page-whatsapp-icon"
        />
      </>
    );
  } else {
    return (
      <>
        <div id="listing-page">
          <Header
            showLandingOptions
            benefitsClick={() =>
              navigate("/", { state: { scroll: "benefits" } })
            }
          />
          <div id="listing-page-filter-section">
            <DropdownBubble
              height="70%"
              width="20%"
              icon={<SearchIcon width="10%" height="70%" />}
              value={getDropdownOptionFromValue(
                municipalityOptions,
                query.municipality,
                locationDefaultValue
              )}
              options={municipalityOptions}
              shadow={false}
              controlFontFamily="SuisseBPIntl-Regular"
              optionFontFamily="SuisseBPIntl-Thin"
              onChange={(n) => updateQuery("municipality", n.value)}
              border
              fontSize="0.8vw"
            />
            <div style={{ width: "0.5%" }} />
            <DropdownBubble
              height="70%"
              width="10%"
              icon={<DollarIcon width="20%" height="70%" />}
              value={getPriceDropdownOption()}
              options={budgetOptions}
              shadow={false}
              controlFontFamily="SuisseBPIntl-Regular"
              optionFontFamily="SuisseBPIntl-Thin"
              onChange={(n) => updateQuery("monthlyRent", n.value)}
              border
              fontSize="0.8vw"
            />
            <div style={{ width: "0.5%" }} />
            <DropdownBubble
              height="70%"
              width="10%"
              icon={<TypeIcon width="20%" height="70%" />}
              shadow={false}
              controlFontFamily="SuisseBPIntl-Regular"
              optionFontFamily="SuisseBPIntl-Thin"
              value={getDropdownOptionFromValue(
                typeOptions,
                query.propertyType,
                typeDefaultValue
              )}
              options={typeOptions}
              onChange={(n) => updateQuery("propertyType", n.value)}
              border
              fontSize="0.8vw"
            />
            <div style={{ width: "0.5%" }} />
            <DropdownBubble
              height="70%"
              width="10%"
              icon={<DoubleBedIcon width="20%" height="70%" />}
              value={getDropdownOptionFromValue(
                roomOptions,
                query.numBedrooms,
                bedroomDefaultValue
              )}
              options={roomOptions}
              shadow={false}
              controlFontFamily="SuisseBPIntl-Regular"
              optionFontFamily="SuisseBPIntl-Thin"
              onChange={(n) => updateQuery("numBedrooms", n.value)}
              border
              fontSize="0.8vw"
            />
            <div style={{ width: "0.5%" }} />
            <DropdownBubble
              height="70%"
              width="10%"
              icon={<ShowerIcon width="20%" height="70%" />}
              value={getDropdownOptionFromValue(
                bathroomOptions,
                query.numBathrooms,
                bathroomDefaultValue
              )}
              options={bathroomOptions}
              shadow={false}
              controlFontFamily="SuisseBPIntl-Regular"
              optionFontFamily="SuisseBPIntl-Thin"
              onChange={(n) => updateQuery("numBathrooms", n.value)}
              border
              fontSize="0.8vw"
            />
            <div style={{ width: "0.5%" }} />
            <div
              id="listing-page-more-filters"
              onClick={() => setShowMoreFiltersModal(true)}
            >
              Más Filtros
              <AddButtonIcon width="20%" height="70%" />
            </div>
            <div style={{ width: "0.5%" }} />
            <div id="clean-filter-bar-button" onClick={resetQuery}>
              Limpiar Filtros
            </div>
          </div>
          <MoreFiltersModal
            open={showMoreFiltersModal}
            onClose={() => setShowMoreFiltersModal(false)}
            query={query}
            setQuery={setQuery}
          />
          <div id="listing-section">
            <div className="listing-page-column">
              <div id="listing-section-headers">
                Propiedades en Renta ({totalResults})
              </div>
              <div id="thumbnail-list" ref={thumbnailStart}>
                {formatThumbnails(properties)}
                <div
                  id="listing-page-navigation-buttons"
                  style={{
                    justifyContent: currentPageInLimit()
                      ? "center"
                      : "space-between",
                  }}
                >
                  {currentPageGreaterThanZero() ? (
                    <RoundedButton
                      onClick={() =>
                        setPageNumber((currNumber) => currNumber - 1)
                      }
                      displayValue="Página anterior"
                      buttonColor="#0F56BF"
                      fontFamily="SuisseBPIntl-Bold"
                      fontSize="1vw"
                      textColor="white"
                      clickable
                      height="2.6vw"
                      width="12vw"
                    />
                  ) : null}
                  {currentPageLessThanMax() ? (
                    <RoundedButton
                      onClick={() =>
                        setPageNumber((currNumber) => currNumber + 1)
                      }
                      displayValue="Siguiente página"
                      buttonColor="#0F56BF"
                      fontFamily="SuisseBPIntl-Bold"
                      fontSize="1vw"
                      textColor="white"
                      clickable
                      height="2.6vw"
                      width="12vw"
                    />
                  ) : null}
                </div>
                <div id="listing-page-navigation-text">
                  Mostrando {pageNumber * itemsPerPage + 1}-
                  {Math.min(
                    pageNumber * itemsPerPage + itemsPerPage,
                    totalResults
                  )}{" "}
                  de {totalResults}
                </div>
              </div>
            </div>
            <div className="listing-page-column">
              <div id="listing-map-container">
                <ListingMap
                  zoom={12}
                  markers={properties.map((property) => ({
                    id: property._id,
                    coordinates: property.location.coordinates,
                    monthlyRent: property.monthlyRent,
                  }))}
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <WhatsappIcon
          onClick={() => {
            window.open(
              encodeURI(
                `https://wa.me/5218182543166?text=Hola, me podrían apoyar a resolver mis dudas?`
              )
            );
          }}
          id="listing-page-whatsapp-icon"
        />
      </>
    );
  }
};

export default ListingPage;
