import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./mobileHeader.css";

import HamburgerModal from "../../components/HamburgerModal/hamburgerModal";

import NavioLogo from "../../assets/logos/navioWhiteTransparent.png";
import { ReactComponent as HamburgerIcon } from "../../assets/icons/hamburger.svg";

const MobileHeader = (props) => {
  const navigate = useNavigate();

  const [isNavModalOpen, setIsNavModalOpen] = useState(false);
  return (
    <div id="mobile-header">
      <img
        id="mobile-navio-header-logo"
        src={NavioLogo}
        alt="Logo"
        onClick={() => navigate("/")}
      />
      <HamburgerIcon
        onClick={() => setIsNavModalOpen(true)}
        id="mobile-navio-header-hamburger-icon"
      />
      <HamburgerModal
        isOpen={isNavModalOpen}
        onClose={() => setIsNavModalOpen(false)}
      >
        <div id="mobile-header-navigation-container">
          <div
            className="mobile-header-navigation-item"
            onClick={() => {
              setIsNavModalOpen(false);
              navigate("/", { state: { scroll: "benefits" } });
            }}
          >
            ¿Por qué Navío?
          </div>
          <div
            className="mobile-header-navigation-item"
            onClick={() => {
              setIsNavModalOpen(false);
              navigate("/", { state: { scroll: "tutorial" } });
            }}
          >
            ¿Cómo funciona?
          </div>
          <div
            className="mobile-header-navigation-item"
            onClick={() => {
              setIsNavModalOpen(false);
              navigate("/publishInfo");
            }}
          >
            Publicar mi propiedad
          </div>
        </div>
      </HamburgerModal>
    </div>
  );
};

export default MobileHeader;
